import './index.css'; // Ensure this import statement is present at the top
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18n}>
      <FluentProvider theme={teamsLightTheme}>
        <App />
      </FluentProvider>
    </I18nextProvider>
);