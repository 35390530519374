// src/components/auth/components/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses,Card, Input, Field, Checkbox, Button, Link, Image, makeStyles, MessageBar, CardHeader, CardFooter } from '@fluentui/react-components';
import { rhemlabs } from '@rhemlabs/rl-theme';
import { AuthContext } from '../context/AuthContext';
import RHEMLogo from '../../../assets/logo.jpeg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: `linear-gradient(135deg, ${rhemlabs[80]} 0%, ${rhemlabs[140]} 100%)`,
  },
  card: {
    width: '100%',
    maxWidth: '400px',
    padding: '2rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  input: {
    marginBottom: '1rem',
  },
  button: {
    alignSelf: 'center',
    padding: '0.5rem 2rem',
  },
  footer: {
    textAlign: 'center',
    paddingTop: '1rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  messageBar: {
    marginBottom: '1rem',
  },
  logo: {
    height: '48px',
    width: '48px',
    borderRadius: '8px',
  },
  pointer: {
    cursor: 'pointer',
  },
});

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setLoading(true);

    const trimmedEmail = email.trim().toLowerCase();
    const trimmedPassword = password.trim();

    if (!trimmedEmail || !trimmedPassword) {
      setErrorMessage('Invalid credentials.');
      setLoading(false);
      return;
    }

    try {
      await login({ email: trimmedEmail, password: trimmedPassword, rememberMe });
      setSuccessMessage('Login successful! Redirecting...');
      setTimeout(() => navigate('/app/dashboard'), 2000);
    } catch (error) {
      setErrorMessage('Invalid credentials.');
    } finally {
      setLoading(false);
    }
  };

  const combinedClasses = mergeClasses(classes.logo, classes.pointer);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <a href="/">
        <Image src={RHEMLogo} alt="Home" className={combinedClasses} aria-label="Home" />
        </a>
        <CardHeader className={classes.header}>
          <h2>Login</h2>
        </CardHeader>
        {errorMessage && (
          <MessageBar className={classes.messageBar} intent="error">
            {errorMessage}
          </MessageBar>
        )}
        {successMessage && (
          <MessageBar className={classes.messageBar} intent="success">
            {successMessage}
          </MessageBar>
        )}
        <form className={classes.form} onSubmit={handleLogin}>
          <Field label="Email">
            <Input
              className={classes.input}
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Field>
          {email && (
            <Field label="Password">
              <Input
                className={classes.input}
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          )}
          <Checkbox 
            label="Remember me" 
            checked={rememberMe} 
            onChange={() => setRememberMe(!rememberMe)} 
          />
          <Link href="/app/forgot-password">Forgot Password?</Link>
          <Button className={classes.button} type="submit" appearance="primary" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>
        <CardFooter className={classes.footer}>
          <p>
            Don't have an account? <a href="/app/loganAI-register">Register here</a>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Login;