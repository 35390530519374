// fluent_ui/src/pages/admin/ProductManagement.js  
// src/pages/admin/ProductManagement.js  
  
import React, { useState, useEffect } from 'react';  
import { Table, TableCell, Button, Input, Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogContent, DialogActions, Field, Textarea } from '@fluentui/react-components';  
import { useLayoutStyles, useMiscStyles } from '@rhemlabs/rl-theme';  
import productService from '../../services/productService';  
  
const ProductManagement = () => {  
  const layoutStyles = useLayoutStyles();  
  const miscStyles = useMiscStyles();  
  const [products, setProducts] = useState([]);  
  const [newProduct, setNewProduct] = useState({ name: '', description: '', price: '' });  
  const [editingProduct, setEditingProduct] = useState(null);  
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);  
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);  
  const [productToDeactivate, setProductToDeactivate] = useState(null);  
  
  useEffect(() => {  
    const fetchProducts = async () => {  
      try {  
        const productsData = await productService.getProducts();  
        setProducts(productsData);  
      } catch (error) {  
        console.error('There was an error fetching the products!', error);  
      }  
    };  
  
    fetchProducts();  
  }, []);  
  
  const handleAddProduct = async () => {  
    try {  
      const addedProduct = await productService.addProduct(newProduct);  
      setProducts(prevProducts => [...prevProducts, addedProduct]);  
      setNewProduct({ name: '', description: '', price: '' });  
      setIsAddDialogOpen(false);  
    } catch (error) {  
      console.error('There was an error adding the product!', error);  
    }  
  };  
  
  const handleEditProduct = async () => {  
    try {  
      const updatedProduct = await productService.updateProduct(editingProduct.id, editingProduct);  
      setProducts(products.map(product => product.id === updatedProduct.id ? updatedProduct : product));  
      setEditingProduct(null);  
      setIsEditDialogOpen(false);  
    } catch (error) {  
      console.error('There was an error updating the product!', error);  
    }  
  };  
  const openDeactivateDialog = (product) => {  
    setProductToDeactivate(product);  
    setIsDeactivateDialogOpen(true);  
  };  
  
  const handleConfirmDeactivateProduct = async () => {  
    if (productToDeactivate) {  
      try {  
        await productService.deactivateProduct(productToDeactivate.id);  
        setProducts(products.filter((product) => product.id !== productToDeactivate.id));  
        setProductToDeactivate(null);  
        setIsDeactivateDialogOpen(false);  
      } catch (error) {  
        console.error('There was an error deactivating the product!', error);  
      }  
    }  
  }; 
  
  return (  
    <div className={layoutStyles.productManagement}>  
      <h2>Product Management</h2>  
      <Button onClick={() => setIsAddDialogOpen(true)}>Add Product</Button>  
      <div className={miscStyles.productTable}>  
        <Table>  
          <thead>  
            <tr>  
              <th>Name</th>  
              <th>Description</th>  
              <th>Prices</th>  
              <th>Actions</th>  
            </tr>  
          </thead>  
          <tbody>  
            {Array.isArray(products) && products.length > 0 ? (  
              products.map((product) => (  
                <tr key={product.id}>  
                  <td>{product.name}</td>  
                  <td>{product.description}</td>  
                  <td>  
                    {product.prices.map((price) => (  
                      <div key={price.id}>  
                        {(price.unit_amount / 100).toFixed(2)} {price.currency.toUpperCase()}  
                        {price.recurring ? ` (${price.recurring.interval})` : ''}  
                      </div>  
                    ))}  
                  </td>  
                  <td>  
                    <Button  
                      onClick={() => {  
                        setEditingProduct(product);  
                        setIsEditDialogOpen(true);  
                      }}  
                    >  
                      Edit  
                    </Button>  
                    <Button onClick={() => openDeactivateDialog(product)}>Deactivate</Button>  
                  </td>  
                </tr>  
              ))  
            ) : (  
              <tr>  
                <td colSpan="4">No products found</td>  
              </tr>  
            )}  
          </tbody>  
        </Table>  
      </div> 
  
      {/* Add Product Dialog */}  
      <Dialog open={isAddDialogOpen} onOpenChange={(event, data) => setIsAddDialogOpen(data.open)}>  
        <DialogSurface>  
          <DialogTitle>Add New Product</DialogTitle>  
          <DialogContent>  
            <Field label="Name">  
              <Input name="name" value={newProduct.name} onChange={e => setNewProduct({ ...newProduct, name: e.target.value })} />  
            </Field>  
            <Field label="Description">  
              <Textarea name="description" value={newProduct.description} onChange={e => setNewProduct({ ...newProduct, description: e.target.value })} />  
            </Field>  
            <Field label="Price (in AUD)">  
              <Input type="number" name="price" value={newProduct.price} onChange={e => setNewProduct({ ...newProduct, price: e.target.value })} />  
            </Field>  
          </DialogContent>  
          <DialogActions>  
            <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>  
            <Button onClick={handleAddProduct} appearance="primary">Add Product</Button>  
          </DialogActions>  
        </DialogSurface>  
      </Dialog>  
  
      {/* Edit Product Dialog */}  
      {editingProduct && (  
        <Dialog open={isEditDialogOpen} onOpenChange={(event, data) => setIsEditDialogOpen(data.open)}>  
          <DialogSurface>  
            <DialogTitle>Edit Product</DialogTitle>  
            <DialogContent>  
              <Field label="Name">  
                <Input name="name" value={editingProduct.name} onChange={e => setEditingProduct({ ...editingProduct, name: e.target.value })} />  
              </Field>  
              <Field label="Description">  
                <Textarea name="description" value={editingProduct.description} onChange={e => setEditingProduct({ ...editingProduct, description: e.target.value })} />  
              </Field>  
              <Field label="Price (in AUD)">  
                <Input type="number" name="price" value={editingProduct.price} onChange={e => setEditingProduct({ ...editingProduct, price: e.target.value })} />  
              </Field>  
            </DialogContent>  
            <DialogActions>  
              <Button onClick={() => { setIsEditDialogOpen(false); setEditingProduct(null); }}>Cancel</Button>  
              <Button onClick={handleEditProduct} appearance="primary">Update Product</Button>  
            </DialogActions>  
          </DialogSurface>  
        </Dialog>  
      )} 

      {/* Deactivate Product Confirmation Dialog */}  
      {productToDeactivate && (  
        <Dialog  
          open={isDeactivateDialogOpen}  
          onOpenChange={(event, data) => {  
            setIsDeactivateDialogOpen(data.open);  
            if (!data.open) {  
              setProductToDeactivate(null);  
            }  
          }}  
        >  
          <DialogSurface>  
            <DialogTitle>Deactivate {productToDeactivate.name}?</DialogTitle>  
            <DialogContent>  
              Are you sure you want to deactivate this product?  
            </DialogContent>  
            <DialogActions>  
              <Button  
                onClick={() => {  
                  setIsDeactivateDialogOpen(false);  
                  setProductToDeactivate(null);  
                }}  
                appearance="secondary"  
                // Using autoFocus to set 'No' as the default focus  
                autoFocus  
              >  
                No  
              </Button>  
              <Button onClick={handleConfirmDeactivateProduct} appearance="primary">  
                Yes  
              </Button>  
            </DialogActions>  
          </DialogSurface>  
        </Dialog>  
      )}  
    </div>  
  );  
};  
  
export default ProductManagement;  
