// fluent_ui/src/AppRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Login from './components/auth/components/Login';
import Register from './components/auth/components/Register';
import CompleteRegistration from './components/auth/components/CompleteRegistration';
import LoganAIPasswordReset from './components/auth/components/LoganAIPasswordReset';
import LoganAICompleteResetPassword from './components/auth/components/LoganAICompletePasswordReset';

import ProtectedRoute from './components/protected/ProtectedRoute';
import ProtectedLayout from './components/protected/ProtectedLayout';
import Dashboard from './pages/secured/Dashboard';
import Profile from './pages/secured/Profile';
import AdminLayout from './pages/admin/AdminLayout';
import UserManagement from './pages/admin/UserManagement';
import ProductManagement from './pages/admin/ProductManagement';

import Home from './pages/home/Home';
import NotFound from './pages/NotFound';
import Partners from './pages/partners/Partners';

//Blog
import CategoryPage from './components/blog/CategoryPage';
import PostPage from './components/blog/PostPage';
import BlogOverview from './components/blog/BlogOverview'

import BookingPage from './pages/forensic-services/Bookings';
import AboutPage from './pages/about/About';
import ContactPage from './pages/contact/Contact';

//LoganAI Routing
import LoganAI from './pages/loganai/LoganAI';
import LoganAIFeatures from './pages/loganai/LoganAIFeatures';
import LoganAIPricing from './pages/loganai/LoganAIPricing';
import LoganAIDemo from './pages/loganai/LoganAIDemo';

//AI For Law Routing
import AIForLaw from './pages/ai-for-law/AIForLaw';
import AIForLawFeatures from './pages/ai-for-law/Features';
import AIForLawPricing from './pages/ai-for-law/Pricing';
import AIForLawDemo from './pages/ai-for-law/Demo';
import AIForLawWaitList from './pages/ai-for-law/WaitList';

//Forensic Services Routing
import ForensicServices from './pages/forensic-services/ForensicServices';
import ForensicServicesPricing from './pages/forensic-services/Pricing';

//Footer Routing
import Terms from './pages/terms/Terms';
import Privacy from './pages/terms/Terms';
import Refund from './pages/terms/Terms';

const AppRoutes = () => (
  <Routes>
    {/* Standard routing */}
    <Route path="/" element={<Home />} />
    
    <Route path="/about" element={<AboutPage />} />
    <Route path="/contact" element={<ContactPage />} />
    
    <Route path="/category/:categoryName" element={<CategoryPage />} />
    <Route path="/category/:categoryName/post/:postName" element={<PostPage />} />
  
    <Route path="/blog" element={<BlogOverview />} />
    <Route path="/blog/:categoryName" element={<CategoryPage />} />
    <Route path="/blog/:categoryName/:postName" element={<PostPage />} />

    <Route path="/poweredby" element={<Partners />} />
    <Route path="/partners" element={<Partners />} />


    {/* Footer Routing*/}
    <Route path="/terms/terms" element={<Terms />}/>
    <Route path="/terms/privacy" element={<Privacy />}/>
    <Route path="/terms/refund" element={<Refund />}/>

    {/* LoganAI Routing */}
    <Route path="/products/logan-ai" element={<LoganAI />} />
    <Route path="/products/logan-ai/features" element={<LoganAIFeatures />} />
    <Route path="/products/logan-ai/pricing" element={<LoganAIPricing />} />
    <Route path="/products/logan-ai/demo" element={<LoganAIDemo />} />

    {/* AI For Law Routing */}
    <Route path="/products/ai-for-law" element={<AIForLaw />} />
    <Route path="/products/ai-for-law/features" element={<AIForLawFeatures />} />
    <Route path="/products/ai-for-law/pricing" element={<AIForLawPricing />} />
    <Route path="/products/ai-for-law/demo" element={<AIForLawDemo />} />
    <Route path="/products/ai-for-law/wait-list" element={<AIForLawWaitList />} />

    {/* Forensic Services Routing */}
    <Route path="/services/forensics" element={<ForensicServices />} />
    <Route path="/services/forensics/pricing" element={<ForensicServicesPricing />} />
    <Route path="/services/forensics/rapid-review/bookings" element={<BookingPage />} />

    {/* Auth routing */}
    
    <Route path="/app/loganAI-login" element={<Login />} />
    <Route path="/app/loganAI-register" element={<Register />} />
    <Route path="/app/loganAI-complete-registration" element={<CompleteRegistration />} />
    <Route path="/app/forgot-password" element={<LoganAIPasswordReset />} />
    <Route path="/app/reset-password" element={<LoganAICompleteResetPassword />} />
    


    {/* Protected routing */}
    <Route path="/app/dashboard" element={
      <ProtectedRoute roles={['user', 'admin']}>
        <ProtectedLayout> 
          <Dashboard />
        </ProtectedLayout> 
      </ProtectedRoute>
    } />
    <Route path="/app/profile" element={
      <ProtectedRoute roles={['user', 'admin']}>
        <ProtectedLayout>
          <Profile />
        </ProtectedLayout>
      </ProtectedRoute>
    } />

    {/* Admin routes */}
    <Route path="/app/admin" element={
     <ProtectedRoute roles={['admin']}>
      <ProtectedLayout>
        <AdminLayout />
      </ProtectedLayout>
      </ProtectedRoute>
    }>
      <Route path="users" element={<UserManagement />} />
      <Route path="products" element={<ProductManagement />} />
      {/* More admin routes */}
    </Route>


    <Route path="*" element={<NotFound />} /> {/* 404 */}
  </Routes>
);

export default AppRoutes;