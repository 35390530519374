// footer/FooterMain.js
import React from 'react';
import { Text, Link, Card, makeStyles, tokens, shorthands, Divider } from '@fluentui/react-components';
import DigiCertSeal from '../DigiCertSeal';
import MicrosoftStartUps from '../MicrosoftStartUps';
import RHEMLabs from '../RHEMLabs';
import PoweredByOpenAI from '../OpenAI';
import BrandSA from '../BrandSA';
import { useFooterStyles, useCardStyles, useLayoutStyles } from '@rhemlabs/rl-theme';
import FooterTop from './FooterTop';

const useDividerStyles = makeStyles ({
  dividerTesting: {
 "::before": {
   ...shorthands.borderColor(tokens.colorStrokeFocus2),
 },
 "::after": {
   ...shorthands.borderColor(tokens.colorStrokeFocus2),
 },
}

})

const FooterMain = ({ theme }) => {
  const footerClasses = useFooterStyles();
  const cardClasses = useCardStyles();
  const layoutClasses = useLayoutStyles();
  const dividerStyles = useDividerStyles();

  return (
    <div className={footerClasses.footer} style={{ height: '100%' }}>
      <Divider className={dividerStyles.dividerTesting} vertical></Divider>
      <Divider className={dividerStyles.dividerTesting}></Divider>
  <div className={layoutClasses.cardGrid} style={{ height: '100%' }}>
    <Card className={cardClasses.partnerCard} apperance="subtle" style={{ height: '500px', gridColumn: 'span 1' }}>
      <RHEMLabs logoType="mono" theme={theme} />
      <Text size={100}>
        The Power of Australian Intelligence requires the representation of All Australians. RHEM Labs acknowledges the Traditional Custodians of country throughout Australia and their connections to land, sea and community. We pay our respect to Elders past and present and extend that respect to all Aboriginal and Torres Strait Islander peoples today.
      </Text>
      <Text size={100}>
        RHEM Labs™, Logan AI™, LoganAI™, Prompt Secrets™, P Prompting Method™, P Prompting Framework, P Method™, P Framework™ and Australian Intelligence™ are trademarks of RHEM Labs Pty Ltd. ​We Improve our products and advertising by using Microsoft Clarity and Google Analytics to see how you use our website. By using our site, you agree that we, Microsoft and Google can collect and use this data. Our privacy statement has more details.
      </Text>
      <Text size={100}>
        All sales are subject to terms and conditions. This site may contain links to affiliate programs. Rest assured, this does not cost you anything extra and we only recommend high valued services we use ourselves.
      </Text>
      <DigiCertSeal />
    </Card>
    <Card className={cardClasses.partnerCard} apperance="subtle" style={{ height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gridColumn: 'span 1' }}>
    <Text variant="large" className={footerClasses.footerHeading}>Company</Text>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li><Link href="/about">About Us</Link></li>
        <li><Link href="/partners">Partners</Link></li>
        <li><Link href="/contact">Contact</Link></li>
      </ul>
      <Text variant="large" className={footerClasses.footerHeading}>Legal</Text>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li><Link href="/terms/terms">Terms of Service</Link></li>
        <li><Link href="/terms/privacy">Privacy Policy</Link></li>
        <li><Link href="/terms/refund">Refund Policy</Link></li>
      </ul>
      <Text variant="large" className={footerClasses.footerHeading}>Services</Text>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li><Link href="/services/forensic">Forensic Services</Link></li>
        <li><Link href="/products/logan-ai">LoganAI</Link></li>
      </ul>
      
    </Card>
    <Card className={cardClasses.partnerCard} apperance="subtle" style={{ height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gridColumn: 'span 1' }}>
      <FooterTop/>

      <Card className={cardClasses.partnerCard}>
          <MicrosoftStartUps logoType="badge" theme={theme} />
        </Card>
        <Card className={cardClasses.partnerCard}>
          <PoweredByOpenAI logoType="badge" theme={theme} />
        </Card>
        <Card className={cardClasses.partnerCard}>
          <BrandSA logoType="mono" theme={theme} />
        </Card>

    </Card>
  </div>
</div>
  );
};

export default FooterMain;