// fluent_ui/src/context/ThemeContext.js
import React, { createContext, useContext, useState } from 'react';
import { RHEMLight, RHEMDark } from '@rhemlabs/rl-theme';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(RHEMLight);

  const toggleTheme = () => {
    setTheme(currentTheme => (currentTheme === RHEMLight ? RHEMDark : RHEMLight));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);