// fluent_ui/src/App.js
import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { FluentProvider } from '@fluentui/react-components';
import { useStyles } from '@rhemlabs/rl-theme';
import AuthProvider from './components/auth/context/AuthContext';
import { ThemeProvider, useTheme } from './context/ThemeContext';

// Components
import Header from './components/shared/header/Header';
import AppRoutes from './AppRoutes';
import Footer from './components/shared/footer/Footer';

const AppContent = () => {
  const styles = useStyles();
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();

// Conditionally render header and footer based on route
const shouldShowHeaderAndFooter = !location.pathname.startsWith('/app');

return (
  <>
    {shouldShowHeaderAndFooter && <Header toggleTheme={toggleTheme} />}
    <div className={styles.container} style={{ backgroundColor: theme.colorNeutralBackground1 }}>
      <main>
        <AppRoutes />
      </main>
    </div>
    {shouldShowHeaderAndFooter && <Footer theme={theme} />}
  </>
);
};

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <AppContentWrapper />
      </ThemeProvider>
    </AuthProvider>
  );
};

const AppContentWrapper = () => {
  const { theme } = useTheme();

  return (
    <FluentProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </FluentProvider>
  );
};

export default App;