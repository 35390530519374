
import * as React from 'react';


export default function LoganAIDemo() {


    return (
    <div>
      {/* AI For Law content to be done. currently using waitlist for sign ups and register interest*/}
    </div>  
    );
};
