// ImageWithCaption.js
import React from 'react';
import { Image, Text } from '@fluentui/react-components';
import './image_with_caption.css'; 

const ImageWithCaption = ({ src, alt, caption }) => {
  return (
    <div className="image-block">
      <Image src={src} alt={alt} />
      {caption && <Text variant="caption1">{caption}</Text>}
    </div>
  );
};

export default ImageWithCaption;