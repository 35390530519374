// ShareButton.js

import React, { useState } from 'react';
import { MenuButton, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { Share20Regular } from '@fluentui/react-icons';
import './share_button.css';

const ShareButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <Menu open={isMenuOpen} onOpenChange={(e, { open }) => setIsMenuOpen(open)}>
        <MenuTrigger disableButtonEnhancement>
          <MenuButton icon={<Share20Regular />} appearance="outline" onClick={onToggleMenu}>
            Share
          </MenuButton>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem disabled onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href), '_blank')}>
              Share on Facebook
            </MenuItem>
            <MenuItem disabled onClick={() => window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href), '_blank')}>
              Share on Twitter
            </MenuItem>
            <MenuItem disabled onClick={() => window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(window.location.href), '_blank')}>
              Share on LinkedIn
            </MenuItem>
            <MenuItem onClick={() => navigator.clipboard.writeText(window.location.href)}>
              Copy Link
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  );
};

export default ShareButton;