// components/blog/BlogOverview.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardFooter, CardHeader, Button, LargeTitle, Title3, CardPreview } from '@fluentui/react-components';
import ShareButton from './ShareButton'; 
import { useCardStyles, useLayoutStyles } from '@rhemlabs/rl-theme';

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

function BlogOverview() {
    const cardStyles = useCardStyles();
    const layoutStyles = useLayoutStyles();
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const postsPerPage = 9;

    useEffect(() => {
        fetch('https://rl.azurewebsites.net/wp-json/wp/v2/posts')
            .then(response => response.json())
            .then(data => {
                const fetchPostsWithMedia = data.map(post => {
                    if (post.featured_media) {
                        return fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/media/${post.featured_media}`)
                            .then(response => response.json())
                            .then(mediaData => {
                                // Check if source_url is available
                                if (mediaData.source_url) {
                                    const baseURL = 'https://rl.azurewebsites.net';
                                    return { ...post, featured_media_url: baseURL + mediaData.source_url };
                                } else {
                                    console.error('No source_url available for post', post.id);
                                    return post;
                                }
                            })
                            .catch(error => {
                                console.error('Error fetching media for post', post.id, ':', error);
                                return post;
                            });
                    }
                    return Promise.resolve(post);
                });
                Promise.all(fetchPostsWithMedia).then(setPosts);
            });

        fetch('https://rl.azurewebsites.net/wp-json/wp/v2/categories')
            .then(response => response.json())
            .then(data => setCategories(data));
    }, []);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        setCurrentPage(1);
    };

    const filteredPosts = posts
        .filter(post => 
            (selectedCategory === '' || post.categories.includes(parseInt(selectedCategory))) &&
            (selectedMonth === '' || new Date(post.date).getMonth() + 1 === parseInt(selectedMonth))
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date));

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="blog-overview">
            <LargeTitle>Blog Overview</LargeTitle>
            <div className="filters">
                <select onChange={handleCategoryChange} value={selectedCategory}>
                    <option value="">All Categories</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
                <select onChange={handleMonthChange} value={selectedMonth}>
                    <option value="">All Months</option>
                    {[...Array(12).keys()].map(month => (
                        <option key={month + 1} value={month + 1}>{new Date(0, month).toLocaleString('default', { month: 'long' })}</option>
                    ))}
                </select>
            </div>
            <div className={layoutStyles.cardGrid}>
                {currentPosts.map(post => (
                    <Card key={post.id} className={cardStyles.card}>
                        <CardPreview>
                            <img 
                                src={post.featured_media_url} 
                                alt={"Feature, Decorative Only."}
                                style={{ width: "100%", height: "200px", objectFit: "cover", objectPosition: "50% 21%" }} 
                            /> 
                        </CardPreview>
                        <CardHeader
                            header={<Title3> {toTitleCase(post.title.rendered)}</Title3>}
                            description={<></>}
                        />
                        <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                        <CardFooter>
                            <Link to={`/post/${post.slug}`}>
                                <Button appearance="primary">Read More</Button>
                            </Link>
                            <ShareButton postSlug={post.slug} postTitle={post.title.rendered} />
                        </CardFooter>
                    </Card>
                ))}
            </div>
            <div className="pagination" style={{ textAlign: 'center' }}>
                {Array.from({ length: Math.ceil(filteredPosts.length / postsPerPage) }, (_, i) => (
                    <button 
                        key={i + 1} 
                        onClick={() => paginate(i + 1)}
                        className={currentPage === i + 1 ? 'active' : ''}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
            
        </div>
    );
}

export default BlogOverview;