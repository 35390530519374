// src/components/protected/ProtectedNavigation.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {Tab, TabList } from '@fluentui/react-components';
import { AuthContext } from '../auth/context/AuthContext';

const ProtectedNavigation = () => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
      <TabList>
        <Tab onClick={logout} >Logout</Tab>
        <Tab onClick={() => navigate('/app/dashboard')}>Dashboard</Tab>
        <Tab onClick={() => navigate('/app/profile')}>Profile</Tab>
        {user.roles.includes('admin') && (
          <>
            <Tab onClick={() => navigate('/app/admin/users')}>User Management</Tab>
            <Tab onClick={() => navigate('/app/admin/products')}>Product Management</Tab>
          </>
        )}
      </TabList>
      
  );
};

export default ProtectedNavigation;