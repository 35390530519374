// src/services/authService.js
import Cookies from 'js-cookie';

const authService = {
  login: async (credentials) => {
    console.debug('Sending login request to server...', credentials);
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials),
      credentials: 'include', // Include cookies in the request
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Login failed:', errorData);
      throw new Error(errorData.message || 'Login failed');
    }
    const data = await response.json();
    console.debug('Login response:', data);
    return data;
  },
  logout: async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // Ensure cookies are sent with the request
      });
      if (!response.ok) throw new Error('Logout failed');
      return response.json();
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  },
  refreshToken: async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/refresh-token`, {
      method: 'POST',
      credentials: 'include',
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Failed to refresh token');
    return data;
  },
  getCurrentUser: async () => {
    const token = Cookies.get('authToken');
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/current-user`, {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    });
    if (!response.ok) throw new Error('Failed to fetch current user');
    return response.json();
  },

  getUserDetails: async () => {
    console.debug('Fetching user details from server...');
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/current-user`, {
      credentials: 'include', // Include credentials in the request
    });
    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    console.debug('User details response:', data);
    return data;
  },
  isAuthenticated: () => {
    return sessionStorage.getItem('authFlag') === 'true';
  },

  // REGISTRATION FLOWS //
  initiateRegistration: async (email) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-reg/v1/initiate-registration`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Initiate registration failed:', errorData);
      throw new Error(errorData.message || 'Initiate registration failed');
    }
    const data = await response.json();
    console.debug('Initiate registration response:', data);
    return data;
  },
  
    validateRegistrationToken: async (email, token) => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-reg/v1/validate-registration-token?email=${email}&token=${token}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      return data.isValid;
    },
    completeRegistration: async (email, token, password) => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-reg/v1/complete-registration`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, token, password }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Complete registration failed:', errorData);
        throw new Error(errorData.message || 'Complete registration failed');
      }
      const data = await response.json();
      console.debug('Complete registration response:', data);
      return data;
    },


    initiatePasswordReset: async (email) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/initiate-password-reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Initiate password reset failed:', errorData);
      throw new Error(errorData.message || 'Initiate password reset failed');
    }
    const data = await response.json();
    console.debug('Initiate password reset response:', data);
    return data;
  },

  validateResetToken: async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/validate-reset-token?token=${token}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    return data.isValid;
  },


  completePasswordReset: async (email, token, newPassword) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/complete-password-reset`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, token, newPassword }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Complete password reset failed:', errorData);
      throw new Error(errorData.message || 'Complete password reset failed');
    }
    const data = await response.json();
    console.debug('Complete password reset response:', data);
    return data;
  },
};

export default authService;