// fluent_ui/src/pages/NotFound.js
import React from 'react';
import { Display, Card, Text } from '@fluentui/react-components';

const NotFound = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <Card>
    <Display>Opps!</Display>
    <Text><p>It seems Logan hasn't built that page yet. It might have been a temporary glitch in the matrix or the fact that we're constantly improving. Don't worry. We've logged the issue and we'll get the AI to fix it.</p></Text>
    </Card>
  </div>
);

export default NotFound;