// home/Home.js
import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Display,
  Title1,
  Subtitle1,
  Button,
  Divider,
  Text,
  makeStyles,
  shorthands,
  tokens,
  typographyStyles,
} from '@fluentui/react-components';
import { Link } from 'react-router-dom';
import { PersonSearchRegular } from '@fluentui/react-icons';
import { useCardStyles, useHeroStyles, useMiscStyles, useLayoutStyles } from '@rhemlabs/rl-theme';

import {
  Carousel,
  CarouselAnnouncer,
  CarouselCard,
  CarouselNav,
  CarouselNavButton,
  CarouselNavContainer,
  CarouselSlider,
} from "@fluentui/react-carousel-preview";

import ForensicBannerImage from '../../assets/3-1-BannerImage-Forensic-1.png';
import AIBannerImage from '../../assets/3-1-BannerImage-AI-1.png';
import LawBannerImage from '../../assets/3-1-BannerImage-Law-1.png';
import HeroImage from '../../assets/generated_00 (1).png';

import LoganAIPricing from '../loganai/LoganAIPricing';

import Spacer from '../../components/shared/Spacer';

const useDividerStyles = makeStyles({
  dividerTesting: {
    "::before": {
      ...shorthands.borderColor(tokens.colorStrokeFocus2),
    },
    "::after": {
      ...shorthands.borderColor(tokens.colorStrokeFocus2),
    },
  },
});

const useClasses = makeStyles({
  bannerCard: {
    alignContent: "center",
    borderRadius: tokens.borderRadiusLarge,
    height: "550px",
    textAlign: "left",
    position: "relative",
    width: "100%", // Ensure the card takes up the full width
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    left: "10%",
    top: "25%",
    background: tokens.colorNeutralBackground1,
    padding: "18px",
    width: "fit-content",
  },
  title: {
    ...typographyStyles.title1,
  },
  subtext: {
    ...typographyStyles.body1,
  },
  container: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto 1fr",
    paddingBottom: "24px",
    boxShadow: tokens.shadow16,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    border: `${tokens.strokeWidthThicker} solid ${tokens.colorNeutralForeground3}`,
    borderRadius: tokens.borderRadiusMedium,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: "10px",
    minHeight: "100px",
  },
  carousel: {
    flex: 1,
    padding: "20px",
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    border: `${tokens.strokeWidthThicker} solid ${tokens.colorNeutralForeground3}`,
    borderBottom: "none",
    borderRadius: tokens.borderRadiusMedium,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: "10px",
  },
  field: {
    flex: 1,
    gridTemplateColumns: "minmax(100px, max-content) 1fr",
  },
  dropdown: {
    maxWidth: "max-content",
  },
  image: {
    width: "100%", // Ensure the image takes up the full width
    height: "100%", // Ensure the image takes up the full height
    objectFit: "cover", // Maintain aspect ratio and cover the entire area
  },
});

const IMAGES = [
  ForensicBannerImage,
  AIBannerImage,
  LawBannerImage,
];

const BannerCard = (props) => {
  const { children, imageSrc, index } = props;
  const classes = useClasses();

  return (
    <CarouselCard
      className={classes.bannerCard}
      aria-label={`${index + 1} of ${IMAGES.length}`}
    >
      <img className={classes.image} src={imageSrc} role="presentation" alt="Banner"/>
      <div className={classes.cardContainer}>
        {children}
      </div>
    </CarouselCard>
  );
};

export default function Home() {
  const cardClasses = useCardStyles();
  const heroClasses = useHeroStyles();
  const miscClasses = useMiscStyles();
  const layoutClasses = useLayoutStyles();
  const dividerStyles = useDividerStyles();
  const classes = useClasses();

  const [isCardVisible, setIsCardVisible] = useState(true);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true); // Set autoplay to enabled by default
  const [autoplayButton, setAutoplayButton] = useState(true);

  const dismissCard = () => {
    setIsCardVisible(false);
  };

  const autoplayProps = autoplayButton
    ? {
        "aria-label": "Enable autoplay",
        checked: autoplayEnabled,
        onCheckedChange: (e, data) => {
          setAutoplayEnabled(data.checked);
        },
        interval: 5000,
      }
    : undefined;

  return (
    <>
      {isCardVisible && (
        <div className={cardClasses.stripeCardContainer}>
          <Card className={cardClasses.stripeCard}>
            <CardHeader header={<Title1>Get LoganAI Today</Title1>} />
            <LoganAIPricing />
            <CardFooter className={cardClasses.stripeFooter}>
              <Link to="" className={miscClasses.linkButton}>
                <Button appearance="outline" as="span" onClick={dismissCard}>No Thanks</Button>
              </Link>
            </CardFooter>
          </Card>
        </div>
      )}

      <Carousel groupSize={1} circular autoplay={autoplayEnabled}>
        <CarouselSlider>
          {IMAGES.map((imageSrc, index) => (
            <BannerCard
              key={`image-${index}`}
              imageSrc={imageSrc}
              index={index}
            >
              {index === 0 && (
                <>
                  <Display>Forensic Experts</Display>
                  <Subtitle1>Helping you understand digital evidence with precision and confidentiality.</Subtitle1>
                  <Link to="/services/forensics/rapid-review/bookings" className={miscClasses.linkButton}>
                    <Button className={miscClasses.button} icon={<PersonSearchRegular />} appearance="primary" as="span">Book a Free Assessment</Button>
                  </Link>
                  <Link to="/contact" className={miscClasses.linkButton}>
                    <Button appearance="outline" as="span">Contact</Button>
                  </Link>
                </>
              )}
              {index === 1 && (
                <>
                  <Display>Era Of <br/>AI</Display>
                  <Subtitle1>Access LoganAI, your expert general purpose AI assistant.</Subtitle1>
                  <Link to="/products/logan-ai" className={miscClasses.linkButton}>
                    <Button className={miscClasses.Button} appearance="primary" as="span">Start Your 14 Day Free Trial</Button>
                  </Link>
                  <Link to="/products/logan-ai" className={miscClasses.linkButton}>
                    <Button appearance="outline">Learn More</Button> 
                  </Link>
                </>
              )}
              {index === 2 && (
                <>
                  <Display>AI For <br/>Law</Display>
                  <Subtitle1>Secure, Private AI for Legal Professionals and Experts</Subtitle1>
                  <Link to="/products/ai-for-law/wait-list" className={miscClasses.linkButton}>
                    <Button appearance="outline" className={miscClasses.Button}>Join The Waitlist</Button>
                  </Link>
                </>
              )}
            </BannerCard>
          ))}
        </CarouselSlider>
        <CarouselNavContainer
          layout="inline"
          autoplay={autoplayProps}
          next={{ "aria-label": "go to next" }}
          prev={{ "aria-label": "go to prev" }}
        >
          <CarouselNav>
            {(index) => (
              <CarouselNavButton aria-label={`Carousel Nav Button ${index}`} />
            )}
          </CarouselNav>
        </CarouselNavContainer>
        <CarouselAnnouncer>
          {(currentIndex, totalSlides, _slideGroupList) => {
            return `Slide ${currentIndex + 1} of ${totalSlides}`;
          }}
        </CarouselAnnouncer>
      </Carousel>
    </>
  );
}