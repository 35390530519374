import React, { createContext, useReducer, useEffect } from 'react';
import authService from '../services/authService';
import userService from '../../../services/userService';

export const AuthContext = createContext();

// Define states
const states = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  AUTHENTICATED: 'AUTHENTICATED',
  LOADING: 'LOADING',
};

// Define actions
const actions = {
  INIT: 'INIT',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  FETCH_USER: 'FETCH_USER',
  UPDATE_USER: 'UPDATE_USER', 
};

// Define initial state
const initialState = {
  user: null,
  state: states.UNAUTHENTICATED,
};

// Define reducer
const reducer = (state, action) => {
  switch (action.type) {
    case actions.INIT:
      return { ...state, state: states.LOADING };
    case actions.LOGIN:
      return { ...state, state: states.LOADING };
    case actions.LOGOUT:
      return { ...state, state: states.UNAUTHENTICATED, user: null };
    case actions.FETCH_USER:
      if (action.error) {
        return { ...state, state: states.UNAUTHENTICATED, user: null };
      }
      return { ...state, state: states.AUTHENTICATED, user: action.user };
    case actions.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.user } };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    initializeUser();
  }, []);

  const initializeUser = async () => {
    dispatch({ type: actions.INIT });

    if (!authService.isAuthenticated()) {
      dispatch({ type: actions.FETCH_USER, error: true });
      return;
    }

    try {
      const response = await authService.getUserDetails();
      if (response.status === 401) {
        sessionStorage.removeItem('authFlag');
        dispatch({ type: actions.FETCH_USER, error: true });
        return;
      }
      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }
      const userDetails = await response.json();
      dispatch({ type: actions.FETCH_USER, user: userDetails.data });
    } catch (error) {
      console.error('Error fetching user details during initialization:', error);
      dispatch({ type: actions.FETCH_USER, error: true });
    }
  };

  const login = async (credentials) => {
    dispatch({ type: actions.LOGIN });

    try {
      const data = await authService.login(credentials);

      if (data && data.accessToken) {
        sessionStorage.setItem('authFlag', 'true');
        sessionStorage.setItem('accessToken', data.accessToken);

        const userDetails = await authService.getUserDetails();
        if (userDetails && userDetails.status === 'success') {
          dispatch({ type: actions.FETCH_USER, user: userDetails.data });
          return true;
        } else {
          dispatch({ type: actions.FETCH_USER, error: true });
        }
      } else {
        dispatch({ type: actions.FETCH_USER, error: true });
      }
    } catch (error) {
      console.error('Login error:', error);
      dispatch({ type: actions.FETCH_USER, error: true });
    }

    return false;
  };

  const logout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      sessionStorage.removeItem('authFlag');
      sessionStorage.removeItem('accessToken');
      dispatch({ type: actions.LOGOUT });
    }
  };

  const updateUser = async (userData) => {
    try {
      const updatedUser = await userService.updateUser(userData);
      dispatch({ type: actions.UPDATE_USER, user: updatedUser });
    } catch (error) {
      console.error('Update user error:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user: state.user, 
      loading: state.state === states.LOADING, 
      login, 
      logout,
      updateUser,
     }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;