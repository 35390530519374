import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../components/auth/context/AuthContext';
import { Input, Button, Field, Card, CardHeader } from '@fluentui/react-components';
import userService from '../../services/userService';

const Profile = () => {
  const { user, updateUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    if (user && user.profile) {
      setFormData({
        firstName: user.profile.firstName || '',
        lastName: user.profile.lastName || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await userService.updateProfile(formData);
      alert("Profile updated successfully");
    } catch (error) {
      console.error('Error updating profile:', error);
      alert("Failed to update profile");
    }
  };

  return (
    <>
      {console.debug('Rendering Profile component in ProtectedRoute')}
      <Card>
        <CardHeader header="Your Profile" />
        <form onSubmit={handleSubmit}>
          <Field label="First Name">
            <Input name="firstName" value={formData.firstName} onChange={handleChange} />
          </Field>
          <Field label="Last Name">
            <Input name="lastName" value={formData.lastName}  onChange={handleChange}/>
          </Field>
          
          <Button type="submit">Save Changes</Button>
        </form>
      </Card>
    </>
  );
};

export default Profile;