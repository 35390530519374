// src/components/protected/ProtectedLayout.js
import React from 'react';
import ProtectedNavigation from './ProtectedNavigation';

const ProtectedLayout = ({ children }) => {
  return (
    <div>
      <ProtectedNavigation />
      <div style={{ padding: '20px' }}>
        {children}
      </div>
    </div>
  );
};

export default ProtectedLayout;