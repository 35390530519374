// partners/Partners.js

import * as React from 'react';
import { Card, CardHeader, Display, Subtitle1 } from '@fluentui/react-components';
import { useMiscStyles } from '@rhemlabs/rl-theme';

import MicrosoftStartUps from '../../components/shared/MicrosoftStartUps';
import PoweredByOpenAI from '../../components/shared/OpenAI';
import BrandSA from '../../components/shared/BrandSA';

export default function LoganAI() {
    const classes = useMiscStyles();

    return (
        <div>
        <Display>Partners</Display>

        <MicrosoftStartUps />

        <PoweredByOpenAI />

        <BrandSA />
        </div>
    );
};