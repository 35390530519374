// loganai/LoganAI.js

import * as React from 'react';
import { Card, CardHeader, Display, Title1, Subtitle1 } from '@fluentui/react-components';
import { useMiscStyles } from '@rhemlabs/rl-theme';
import LoganAIPricing from './LoganAIPricing.js';
import FeatureComparison from './FeatureComparison.js';

export default function LoganAI() {
    const classes = useMiscStyles();

    return (
    <div style={{padding: (10)}}>

        <Display>AI For The Masses</Display>
        <br />
        <Subtitle1 style={{height: `${1.5 * 3}em`}}>LoganAI with ElemenTools</Subtitle1>
        
        <Card appearance="'filled'" style={{margin: "10vw"}}>
            <LoganAIPricing />
        </Card>
        
        <Card style={{margin: "10vw"}}>
        <CardHeader 
                header={<Title1>Features</Title1>}
                />
        
        <FeatureComparison/>
        </Card>
    </div>
    );
};