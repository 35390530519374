// terms/Terms.js
import React from 'react';
import { Button, Text, Card, CardHeader, CardFooter, Display } from '@fluentui/react-components';
import PrivacyPolicy from '../../assets/terms/PrivacyPolicy.pdf';
import WebsitePrivacyPolicy from '../../assets/terms/websitePrivacyPolicy.pdf';
import { useMiscStyles } from '@rhemlabs/rl-theme';

const Terms = () => {
  const styles = useMiscStyles();
  const policies = [
    { name: 'Privacy Policy', link: PrivacyPolicy },
    { name: 'Website Privacy Policy', link: WebsitePrivacyPolicy },
    { name: 'Apple EULA', link: 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/' },
    { name: 'Refund Policy', link: 'path/to/refund-policy.pdf' }
  ];

  return (
    <div className={styles.container}>
      <Display>
        Policy Downloads
      </Display>
      {policies.map(policy => (
        <Card key={policy.name} className={styles.card}>
          <CardHeader>
            <Text variant="medium">{policy.name}</Text>
          </CardHeader>
          <CardFooter>
            <Button
              as="a"
              href={policy.link}
              download
              appearance="primary"
            >
              Download {policy.name}
            </Button>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default Terms;