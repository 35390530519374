import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Button, MessageBar, makeStyles } from '@fluentui/react-components';
import authService from '../services/authService';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: '#f5f5f5',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    maxWidth: '400px',
    padding: '2rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    background: '#fff',
  },
  input: {
    marginBottom: '1rem',
  },
  button: {
    alignSelf: 'center',
    padding: '0.5rem 2rem',
  },
  messageBar: {
    marginBottom: '1rem',
  },
});

const CompleteRegistration = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const query = new URLSearchParams(location.search);
  const urlEmail = query.get('email');
  const token = query.get('token');

  useEffect(() => {
    if (urlEmail && token) {
      setEmail(urlEmail); // Set email in state
      validateRegistrationToken(urlEmail, token);
    } else {
      setErrorMessage('Invalid registration link.');
    }
  }, [urlEmail, token]);

  const validateRegistrationToken = async (email, token) => {
    try {
      const isValid = await authService.validateRegistrationToken(email, token);
      if (!isValid) {
        setErrorMessage('Invalid or expired token. Please request a new registration link.');
      }
    } catch (error) {
      setErrorMessage('Error validating token. Please try again later.');
    }
  };

  const handleCompleteRegistration = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrorMessage('');

    if (!password) {
      setErrorMessage('Please enter a password.');
      return;
    }

    try {
      await authService.completeRegistration(email, token, password);
      setMessage('Registration complete! Redirecting to login...');
      setTimeout(() => navigate('/app/loganAI-login'), 3000);
    } catch (error) {
      setErrorMessage('Failed to complete registration. Please try again.');
    }
  };

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={handleCompleteRegistration}>
        <h2>Complete Registration</h2>
        {errorMessage && (
          <MessageBar className={classes.messageBar} intent="error">
            {errorMessage}
          </MessageBar>
        )}
        {message && (
          <MessageBar className={classes.messageBar} intent="success">
            {message}
          </MessageBar>
        )}
        <Input
          className={classes.input}
          type="email"
          placeholder="Email"
          value={email}
          required
          disabled
        />
        <Input
          className={classes.input}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={!!errorMessage} // Disable input if there's an error message
        />
        <Button className={classes.button} type="submit" appearance="primary" disabled={!!errorMessage}>
          Complete Registration
        </Button>
      </form>
    </div>
  );
};

export default CompleteRegistration;