// src/pages/admin/UserManagement.js  
// Description: User Management component in the admin panel.  
  
import React, { useState, useEffect } from 'react';  
import {  
  Table,  
  Button,  
  Input,  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  Field,  
  Dropdown,  
  Option,  
} from '@fluentui/react-components';  
import { useLayoutStyles, useMiscStyles } from '@rhemlabs/rl-theme';  
import userService from '../../services/userService';  
  
const UserManagement = () => {  
  const layoutStyles = useLayoutStyles();  
  const miscStyles = useMiscStyles();  
  const [users, setUsers] = useState([]);  
  const [newUser, setNewUser] = useState({ email: '', roles: [] });  
  const [editingUser, setEditingUser] = useState(null);  
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);  
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);  
  const [selectedReceipt, setSelectedReceipt] = useState(null);  
  const [isReceiptDialogOpen, setIsReceiptDialogOpen] = useState(false);  
  
  useEffect(() => {  
    const fetchUsers = async () => {  
      try {  
        const usersData = await userService.getUsers();  
        setUsers(usersData);  
      } catch (error) {  
        console.error('There was an error fetching the users!', error);  
      }  
    };  
  
    fetchUsers();  
  }, []);  
  
  const roleOptions = [  
    { key: 'user', text: 'User' },  
    { key: 'admin', text: 'Admin' },  
    { key: 'subscriber', text: 'Subscriber' },  
    { key: 'test_user', text: 'Test User' },  
    { key: 'email-confirmed-user', text: 'Email Confirmed User' },  
    // Add other roles as needed  
  ];  
  
  const handleAddUser = async () => {  
    try {  
      const userData = {  
        email: newUser.email,  
        roles: newUser.roles.join(','), // Convert array to comma-separated string  
      };  
      const addedUser = await userService.addUser(userData);  
      setUsers((prevUsers) => [...prevUsers, addedUser]);  
      setNewUser({ email: '', roles: [] });  
      setIsAddDialogOpen(false);  
    } catch (error) {  
      console.error('There was an error adding the user!', error);  
    }  
  };  
  
  const handleEditUser = async () => {  
    try {  
      const userData = {  
        email: editingUser.email,  
        roles: editingUser.roles.join(','), // Convert array to comma-separated string  
      };  
      const updatedUser = await userService.updateUser(editingUser.id, userData);  
      setUsers(users.map((user) => (user.id === updatedUser.id ? updatedUser : user)));  
      setEditingUser(null);  
      setIsEditDialogOpen(false);  
    } catch (error) {  
      console.error('There was an error updating the user!', error);  
    }  
  };  
  
  const fetchReceiptDetails = async (receiptId) => {  
    try {  
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/rl-auth/v1/receipts/${receiptId}`, {  
        credentials: 'include',  
      });  
      if (!response.ok) throw new Error('Failed to fetch receipt details');  
      const data = await response.json();  
      return data.data;  
    } catch (error) {  
      console.error('Error fetching receipt details:', error);  
      throw error;  
    }  
  };  
  
  const handleReceiptClick = async (receiptId) => {  
    try {  
      const receiptDetails = await fetchReceiptDetails(receiptId);  
      setSelectedReceipt(receiptDetails);  
      setIsReceiptDialogOpen(true);  
    } catch (error) {  
      console.error('Error handling receipt click:', error);  
    }  
  };  
  
  const handleDeleteUser = async (userId) => {  
    try {  
      await userService.deleteUser(userId);  
      setUsers(users.filter((user) => user.id !== userId));  
    } catch (error) {  
      console.error('Error deleting user:', error);  
    }  
  };  
  
  const handleResetPassword = async (email) => {  
    try {  
      await userService.initiatePasswordReset(email);  
      alert('Password reset initiated.');  
    } catch (error) {  
      console.error('Error initiating password reset:', error);  
    }  
  };  
  
  return (  
    <div className={layoutStyles.userManagement}>  
      <h2>User Management</h2>  
      <Button onClick={() => setIsAddDialogOpen(true)}>Add User</Button>  
      <div className={miscStyles.userTable}>  
        <Table>  
          <thead>  
            <tr>  
              <th>Email</th>  
              <th>Roles</th>  
              <th>Receipts</th>  
              <th>Actions</th>  
            </tr>  
          </thead>  
          <tbody>  
            {Array.isArray(users) && users.length > 0 ? (  
              users.map((user) => (  
                <tr key={user.id}>  
                  <td>{user.email}</td>  
                  <td>{user.roles.join(', ')}</td>  
                  <td>  
                    {user.receipts && user.receipts.length > 0 ? (  
                      <ul>  
                        {user.receipts.map((receiptId) => (  
                          <li key={receiptId}>  
                            <Button appearance="link" onClick={() => handleReceiptClick(receiptId)}>  
                              {receiptId}  
                            </Button>  
                          </li>  
                        ))}  
                      </ul>  
                    ) : (  
                      'No receipts'  
                    )}  
                  </td>  
                  <td>  
                    <Button  
                      onClick={() => {  
                        setEditingUser(user);  
                        setIsEditDialogOpen(true);  
                      }}  
                    >  
                      Edit  
                    </Button>  
                    <Button onClick={() => handleDeleteUser(user.id)}>Delete</Button>  
                    <Button onClick={() => handleResetPassword(user.email)}>Reset Password</Button>  
                  </td>  
                </tr>  
              ))  
            ) : (  
              <tr>  
                <td colSpan="4">No users found</td>  
              </tr>  
            )}  
          </tbody>  
        </Table>  
      </div>  
  
      {/* Add User Dialog */}  
      <Dialog open={isAddDialogOpen} onOpenChange={(event, data) => setIsAddDialogOpen(data.open)}>  
        <DialogSurface>  
          <DialogTitle>Add New User</DialogTitle>  
          <DialogContent>  
            <Field label="Email">  
              <Input  
                name="email"  
                value={newUser.email}  
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}  
              />  
            </Field>  
            <Field label="Roles">  
              <Dropdown  
                placeholder="Select roles"  
                multiselect  
                selectedOptions={newUser.roles}  
                onOptionSelect={(event, data) => {  
                  setNewUser({ ...newUser, roles: data.selectedOptions });  
                }}  
              >  
                {roleOptions.map((option) => (  
                  <Option key={option.key} value={option.key}>  
                    {option.text}  
                  </Option>  
                ))}  
              </Dropdown>  
            </Field>  
          </DialogContent>  
          <DialogActions>  
            <Button onClick={() => setIsAddDialogOpen(false)}>Cancel</Button>  
            <Button onClick={handleAddUser} appearance="primary">  
              Add User  
            </Button>  
          </DialogActions>  
        </DialogSurface>  
      </Dialog>  
  
      {/* Edit User Dialog */}  
      {editingUser && (  
        <Dialog open={isEditDialogOpen} onOpenChange={(event, data) => setIsEditDialogOpen(data.open)}>  
          <DialogSurface>  
            <DialogTitle>Edit User</DialogTitle>  
            <DialogContent>  
              <Field label="Email">  
                <Input  
                  name="email"  
                  value={editingUser.email}  
                  onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}  
                />  
              </Field>  
              <Field label="Roles">  
                <Dropdown  
                  placeholder="Select roles"  
                  multiselect  
                  selectedOptions={editingUser.roles}  
                  onOptionSelect={(event, data) => {  
                    setEditingUser({ ...editingUser, roles: data.selectedOptions });  
                  }}  
                >  
                  {roleOptions.map((option) => (  
                    <Option key={option.key} value={option.key}>  
                      {option.text}  
                    </Option>  
                  ))}  
                </Dropdown>  
              </Field>  
            </DialogContent>  
            <DialogActions>  
              <Button  
                onClick={() => {  
                  setIsEditDialogOpen(false);  
                  setEditingUser(null);  
                }}  
              >  
                Cancel  
              </Button>  
              <Button onClick={handleEditUser} appearance="primary">  
                Update User  
              </Button>  
            </DialogActions>  
          </DialogSurface>  
        </Dialog>  
      )}  
  
      {/* Receipt Details Dialog */}  
      {selectedReceipt && (  
        <Dialog open={isReceiptDialogOpen} onOpenChange={(event, data) => setIsReceiptDialogOpen(data.open)}>  
          <DialogSurface>  
            <DialogTitle>Receipt Details</DialogTitle>  
            <DialogContent>  
              <div>  
                <p><strong>Receipt ID:</strong> {selectedReceipt.id}</p>  
                <p><strong>Customer Email:</strong> {selectedReceipt.customerEmail}</p>  
                <p><strong>Amount Total:</strong> {selectedReceipt.amountTotal}</p>  
                <p><strong>Currency:</strong> {selectedReceipt.currency.toUpperCase()}</p>  
                <p><strong>Payment Status:</strong> {selectedReceipt.paymentStatus}</p>  
                <p><strong>Fulfillment Status:</strong> {selectedReceipt.fulfillmentStatus}</p>  
                <p><strong>Items:</strong></p>  
                <ul>  
                  {selectedReceipt.items.map((item) => (  
                    <li key={item.productId}>  
                      {item.name} (Quantity: {item.quantity}, Amount: {item.amountTotal})  
                    </li>  
                  ))}  
                </ul>  
                {/* Add more receipt details as needed */}  
              </div>  
            </DialogContent>  
            <DialogActions>  
              <Button onClick={() => setIsReceiptDialogOpen(false)}>Close</Button>  
            </DialogActions>  
          </DialogSurface>  
        </Dialog>  
      )}  
    </div>  
  );  
};  
  
export default UserManagement;  
