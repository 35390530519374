// MicrosoftStartUps.js
import React, { useEffect, useState } from 'react';
import { Image, Link } from '@fluentui/react-components';
import BadgeDark from '../../assets/partnerLogos/MS_Startups_Celebration_Badge_Dark.png';
import BadgeLight from '../../assets/partnerLogos/MS_Startups_Celebration_Badge_Light.png';
import MonoBlack from '../../assets/partnerLogos/MS_Startups_FH_lockup_hrz_1C_Blk.png';
import MonoWhite from '../../assets/partnerLogos/MS_Startups_FH_lockup_hrz_1C_Wht.png';
import { RHEMDark } from '@rhemlabs/rl-theme';

const MicrosoftStartUps = ({ theme, logoType }) => {
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    const isDarkTheme = theme === RHEMDark;
    switch (logoType) {
      case 'badge':
        setLogoSrc(isDarkTheme ?  BadgeDark : BadgeLight);
        break;
      case 'mono':
        setLogoSrc(isDarkTheme ? MonoWhite : MonoBlack);
        break;
      default:
        setLogoSrc(isDarkTheme ? BadgeDark : BadgeLight); // Fallback logic
    }
  }, [theme, logoType]);

  return (
    <div style={{ height: 'auto', width: 180, padding: 8 }}>
        <Link as="a" href="https://www.microsoft.com/en-au/startups/" target="_blank" nline="true">
            <Image 
                src={logoSrc} 
                alt="Microsoft Startups Logo" 
                fit="contain"
            />
        </Link>
    </div>
);
};

export default MicrosoftStartUps;