// src/components/shared/header/header_bottom.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, MenuButton, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { useHeaderStyles } from '@rhemlabs/rl-theme';
import { Home32Regular } from '@fluentui/react-icons';

function HeaderBottom() {
    const navigate = useNavigate(); // React Router hook for navigation
    const [topCategories, setTopCategories] = useState([]);
    const [subcategories, setSubcategories] = useState({}); // Store subcategories for each category
    const styles = useHeaderStyles();

    useEffect(() => {
        const fetchTopCategories = async () => {
            try {
                // 1. Fetch top-level categories
                const categoriesResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/categories?parent=0');
                const categoriesData = await categoriesResponse.json();

                // 2. Fetch all posts to count posts for each category
                const postsResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/posts');
                const postsData = await postsResponse.json();

                // 3. Create a map to count posts for each category
                const categoryPostCounts = new Map();
                postsData.forEach(post => {
                    post.categories.forEach(categoryId => {
                        categoryPostCounts.set(categoryId, (categoryPostCounts.get(categoryId) || 0) + 1);
                    });
                });

                // 4. Combine category data with post counts and sort
                const categoriesWithCounts = categoriesData
                    .map(category => ({
                        ...category,
                        count: categoryPostCounts.get(category.id) || 0
                    }))
                    .sort((a, b) => b.count - a.count)
                    .slice(0, 4);

                setTopCategories(categoriesWithCounts);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchTopCategories();
    }, []);

    const fetchSubcategories = async (categoryId) => {
        try {
            const subcategoriesResponse = await fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/categories?parent=${categoryId}`);
            const subcategoriesData = await subcategoriesResponse.json();

            // Fetch post counts for subcategories
            const postsResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/posts');
            const postsData = await postsResponse.json();

            const categoryPostCounts = new Map();
            postsData.forEach(post => {
                post.categories.forEach(categoryId => {
                    categoryPostCounts.set(categoryId, (categoryPostCounts.get(categoryId) || 0) + 1);
                });
            });

            const subcategoriesWithCounts = subcategoriesData.map(subcategory => ({
                ...subcategory,
                count: categoryPostCounts.get(subcategory.id) || 0
            }));

            setSubcategories(prevState => ({
                ...prevState,
                [categoryId]: subcategoriesWithCounts
            }));
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    const handleLinkClick = (event, slug) => {
        event.preventDefault();
        navigate(`/category/${slug}`);
    };

    return (
        <div className={styles.headerBottom}>
            <Link to="/" className={styles.categoryMenuButton}>
                <Button appearance="transparent" as="span">< Home32Regular /></Button>
            </Link>

            {topCategories.map(category => (
                <Menu key={category.id}>
                    <MenuTrigger>
                        <MenuButton 
                            className={styles.categoryMenuButton}
                            appearance="subtle"
                            disabled={category.count === 0} // Disable if no posts in this category
                            onClick={() => fetchSubcategories(category.id)}
                        >
                            {category.name}
                        </MenuButton>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                onClick={(event) => handleLinkClick(event, category.slug)}
                                disabled={category.count === 0} // Disable if no posts in this category
                            >
                                {category.name}
                            </MenuItem>
                            {subcategories[category.id] && 
                                subcategories[category.id].map(subcategory => (
                                    <MenuItem
                                        key={subcategory.id}
                                        onClick={(event) => handleLinkClick(event, subcategory.slug)}
                                        disabled={subcategory.count === 0} // Disable if no posts in this subcategory
                                        className={styles.nestedItem}
                                    >
                                        <span className={styles.bulletIcon}>•</span>{subcategory.name}
                                    </MenuItem>
                                ))
                            }
                        </MenuList>
                    </MenuPopover>
                </Menu>
            ))}
            <Link to="/about" className={styles.categoryMenuButton}>
                <Button className={styles.categoryMenuButton} appearance="transparent" as="span">Who We Are</Button>
            </Link>
            <Link to="/results" className={styles.categoryMenuButton}>
                <Button className={styles.categoryMenuButton} appearance="transparent" as="span">Our Results</Button>
            </Link>
            <Link to="/contact" className={styles.categoryMenuButton}>
                <Button className={styles.categoryMenuButton} appearance="transparent" as="span">Contact Us</Button>
            </Link>
        </div>
    );
}

export default HeaderBottom;