// src/components/shared/header/HeaderMobile.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Image, MenuButton, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Switch, Divider } from '@fluentui/react-components';
import { Home32Regular, LineHorizontal320Regular,  DarkThemeRegular } from '@fluentui/react-icons';
import RHEMLogo from '../../../assets/logo.jpeg';
import { useHeaderStyles } from '@rhemlabs/rl-theme';

function HeaderMobile({ toggleTheme }) {
    const navigate = useNavigate();
    const [menuData, setMenuData] = useState([]);
    const styles = useHeaderStyles();

    useEffect(() => {
        const fetchMenuData = async () => {
            try {
                const categoriesResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/categories?parent=0');
                const categoriesData = await categoriesResponse.json();

                const postsResponse = await fetch('https://rl.azurewebsites.net/wp-json/wp/v2/posts');
                const postsData = await postsResponse.json();

                const categoryPostCounts = new Map();
                postsData.forEach(post => {
                    post.categories.forEach(categoryId => {
                        categoryPostCounts.set(categoryId, (categoryPostCounts.get(categoryId) || 0) + 1);
                    });
                });

                const categoriesWithSubcategories = await Promise.all(
                    categoriesData.map(async category => {
                        const subcategoriesResponse = await fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/categories?parent=${category.id}`);
                        const subcategoriesData = await subcategoriesResponse.json();
                        const subcategoriesWithCounts = subcategoriesData.map(subcategory => ({
                            ...subcategory,
                            count: categoryPostCounts.get(subcategory.id) || 0
                        }));
                        return { ...category, subcategories: subcategoriesWithCounts, count: categoryPostCounts.get(category.id) || 0 };
                    })
                );

                setMenuData(categoriesWithSubcategories.sort((a, b) => b.count - a.count).slice(0, 4)); 
            } catch (error) {
                console.error("Error fetching data:", error);
                setMenuData([]);
            }
        };

        fetchMenuData();
    }, []);

    const handleLinkClick = (event, url) => {
        event.preventDefault();
        navigate(url);
    };

    return (
        <div className={styles.headerMain}>
            <div className={styles.headerColumnLeft}>
                <Link to="/" className={styles.headerButton}>
                    <Image src={RHEMLogo} alt="RHEM Labs Logo" height={48} width={48} />
                </Link>
            </div>

            <div className={styles.headerColumnRight}>
                <Menu>
                    <MenuTrigger>
                        <MenuButton icon={<LineHorizontal320Regular />} className={styles.menuButton} appearance="subtle" /> 
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem onClick={(e) => handleLinkClick(e, '/')} icon={<Home32Regular />} className={styles.centeredIcon}>
                                Home
                            </MenuItem>
                            <MenuItem onClick={(e) => handleLinkClick(e, '/app/loganAI-login')} className={styles.menuButton}>
                                Login
                            </MenuItem>  
                            <Divider />
                            {menuData.map(category => (
                                <Menu key={category.id}>
                                    <MenuTrigger>
                                        <MenuItem disabled={category.count === 0}>
                                            {category.name}
                                        </MenuItem>
                                    </MenuTrigger>
                                    {category.subcategories.length > 0 && (
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuItem onClick={(e) => handleLinkClick(e, `/category/${category.slug}`)} className={styles.centeredIcon}>
                                                    {category.name}
                                                </MenuItem>
                                                {category.subcategories.map(subcategory => (
                                                    <MenuItem
                                                        key={subcategory.id}
                                                        onClick={(e) => handleLinkClick(e, `/category/${subcategory.slug}`)}
                                                        className={styles.nestedItem}
                                                        disabled={subcategory.count === 0}
                                                    >
                                                        {subcategory.name}
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </MenuPopover>
                                    )}
                                </Menu>
                            ))}
                            <MenuItem onClick={(e) => handleLinkClick(e, '/who-we-are')}>
                                Who we are
                            </MenuItem>
                            <MenuItem onClick={(e) => handleLinkClick(e, '/our-results')}>
                                Our Results
                            </MenuItem>
                            <Divider />
                            <MenuItem className={styles.centeredIcon}>
                                <Switch onClick={toggleTheme} label="Dark Mode" icon={<DarkThemeRegular />} className={styles.centeredIcon} />
                            </MenuItem> 
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </div>
    );
}

export default HeaderMobile;