// src/components/shared/header/header_top.js
import { Switch, Text } from '@fluentui/react-components';
import { useHeaderStyles } from '@rhemlabs/rl-theme';

function HeaderTop({ toggleTheme }) {
    const classes = useHeaderStyles();
    return (
        <div className={classes.headerTop}>
            <Text>&copy; RHEM Labs Pty Ltd ACN: 672 769 354</Text>
            <Switch onClick={toggleTheme} aria-label="Toggle Dark Mode" />
        </div>
    );
}

export default HeaderTop;