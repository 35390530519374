// contact/Contact.js
import React, { useState, useEffect } from 'react';
import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components';
import { Field, Button, Display, Subtitle1, Input, Text, Textarea, Divider, Spinner } from '@fluentui/react-components';
import { EmailClient } from '@azure/communication-email';

import { useMiscStyles } from '@rhemlabs/rl-theme';

const generateCaptcha = () => {
  const num1 = Math.floor(Math.random() * 10);
  const num2 = Math.floor(Math.random() * 10);
  return { question: `${num1} + ${num2}`, answer: (num1 + num2).toString() };
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ContactUs = () => {
  const styles = useMiscStyles();
  const [formData, setFormData] = useState({ name: '', email: '', message: '', captcha: '' });
  const [errors, setErrors] = useState({ name: '', email: '', message: '', captcha: '' });
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null); // null, 'success', 'error'

  useEffect(() => {
    setCaptcha(generateCaptcha());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    // Basic validation
    if (!formData.name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Please enter a contact name' }));
      hasErrors = true;
    }
    if (!formData.email || !emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
      hasErrors = true;
    }
    if (!formData.message) {
      setErrors((prevErrors) => ({ ...prevErrors, message: 'Please enter your message' }));
      hasErrors = true;
    }
    if (formData.captcha !== captcha.answer) {
      setErrors((prevErrors) => ({ ...prevErrors, captcha: 'Incorrect CAPTCHA answer' }));
      hasErrors = true;
    }

    if (!hasErrors) {
      setIsSubmitting(true);
      setSubmitSuccess(null);

      // Sending email using Azure Communication Services
      const connectionString = process.env.REACT_APP_EMAIL_CONNECTION_STRING;
      const senderAddress = process.env.REACT_APP_EMAIL_SENDER_ADDRESS;

      // Ensure these values are defined
      if (!connectionString || !senderAddress) {
        console.error('Environment variables are not set correctly.');
        setIsSubmitting(false);
        setSubmitSuccess('error');
        return;
      }

      const client = new EmailClient(connectionString);

      const emailMessage = {
        senderAddress,
        content: {
          subject: 'Contact Form Submission',
          plainText: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
        },
        recipients: {
          to: [{ address: process.env.REACT_APP_EMAIL_DEFAULT_RECIPIENT }],
        },
      };

      try {
        const poller = await client.beginSend(emailMessage);
        const response = await poller.pollUntilDone();

        if (response.status === 'Succeeded') {
          setSubmitSuccess('success');
        } else {
          console.error('Error sending email:', response);
          setSubmitSuccess('error');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitSuccess('error');
      } finally {
        setIsSubmitting(false);
        setFormData({ name: '', email: '', message: '', captcha: '' });
        setCaptcha(generateCaptcha());
      }
    }
  };

  return (
    <main>
      <heading style={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          margin: "10px"
        }}>
        <Display as="h1">Contact</Display>
        <Text as="p">Fill in the form and one of our friendly staff will get back to you as soon as possible.</Text>
      </heading>

      <section style={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}>
        
        <Divider vertical className={styles.CustomVerticalDivider} />
        <Divider className={styles.CustomDivider} />
        {submitSuccess === 'success' && (
          <MessageBar intent="success">
            <MessageBarBody>
              <MessageBarTitle>Success</MessageBarTitle>
              Email sent successfully!
            </MessageBarBody>
          </MessageBar>
        )}
        {submitSuccess === 'error' && (
          <MessageBar intent="error">
            <MessageBarBody>
              <MessageBarTitle>Error</MessageBarTitle>
              There was an error sending your email. Please try again.
            </MessageBarBody>
          </MessageBar>
        )}
        {isSubmitting ? (
          <Spinner label="Submitting..." />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className={styles.FormGroup}>
              <Field
                label="Name"
                required
                placeholder="Your name"
                validationState={errors.name ? 'invalid' : 'none'}
                validationMessage={errors.name}
              >
                <Input size="large" name="name" onChange={handleChange} value={formData.name} />
              </Field>
            </div>
            <div className={styles.FormGroup}>
              <Field
                label="Email"
                
                required
                placeholder="Your email"
                validationState={errors.email ? 'invalid' : 'none'}
                validationMessage={errors.email}
              >
                <Input size="large" name="email" onChange={handleChange} value={formData.email} />
              </Field>
            </div>
            <div className={styles.FormGroup}>
              <Field
                label="Message"
                required
                placeholder="Your message"
                multiline
                validationState={errors.message ? 'invalid' : 'none'}
                validationMessage={errors.message}
              >
                <Textarea 
                  size="large"
                    style={{ height: '200px' }} 
                    name="message" 
                    resize="vertical" 
                    onChange={handleChange} 
                    value={formData.message} 
                  />
              </Field>
            </div>
            <div className={styles.FormGroup}>
              <Field
                label={`CAPTCHA: What is ${captcha.question}?`}
                required
                validationState={errors.captcha ? 'invalid' : 'none'}
                validationMessage={errors.captcha}
              >
                <Input size="large" name="captcha" onChange={handleChange} value={formData.captcha} />
              </Field>
            </div>
            <Button type="submit" appearance="primary">Submit</Button>
          </form>
        )}
        

      </section>
      <section>
        <Divider className={styles.CustomDivider} />
        <Divider vertical className={styles.CustomVerticalDivider} />
      </section>
      <section style={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Display as="h1">Our Location</Display>
        <Subtitle1 as="h2">By Appointment only:</Subtitle1>
        <Text as="p"> 16 Fraser Avenue St Agnes South Australia </Text>
      </section>
      </main>
 );
};

export default ContactUs;