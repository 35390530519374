// BrandSA.js
import React, { useEffect, useState } from 'react';
import { Image } from '@fluentui/react-components';
import BadgeDark from '../../assets/partnerLogos/State Brand horizontal reversed.png';
import BadgeLight from '../../assets/partnerLogos/State Brand horizontal mono.png';
import MonoBlack from '../../assets/partnerLogos/State Brand horizontal mono.png';
import MonoWhite from '../../assets/partnerLogos/State Brand horizontal reversed.png';
import { RHEMDark } from '@rhemlabs/rl-theme';

const BrandSA = ({ theme, logoType }) => {
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    const isDarkTheme = theme === RHEMDark;
    switch (logoType) {
      case 'badge':
        setLogoSrc(isDarkTheme ?  BadgeDark : BadgeLight);
        break;
      case 'mono':
        setLogoSrc(isDarkTheme ? MonoWhite : MonoBlack);
        break;
      default:
        setLogoSrc(isDarkTheme ? BadgeDark : BadgeLight); // Fallback logic
    }
  }, [theme, logoType]);

  return (
    <div style={{ height: 'auto', width: 180, padding: 8 }}>
        <Image 
            src={logoSrc} 
            alt="Proudly South Australian Badge" 
            fit="contain"
        />
    </div>
);
};

export default BrandSA;