// src/pages/admin/AdminSidebar.js
/**
 * File: src/pages/admin/AdminSidebar.js
 * Description: The sidebar for the admin section, providing navigation links to different admin functionalities.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, MenuList } from '@fluentui/react-components';
import { useLayoutStyles } from '@rhemlabs/rl-theme';

const AdminSidebar = () => {
  const styles = useLayoutStyles();

  return (
    <div className={styles.adminSidebar}>
      <Menu>
        <MenuList>
          <MenuItem>
            <Link to="/admin/users">User Management</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/admin/roles">Role Management</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/admin/products">Product Management</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/admin/analytics">Analytics</Link>
          </MenuItem>
          {/* Add more links as needed */}
        </MenuList>
      </Menu>
    </div>
  );
};

export default AdminSidebar;