import React, { useContext } from 'react';
import { AuthContext } from '../../components/auth/context/AuthContext';
import { Button, Text, Card, CardHeader, CardFooter, Display} from '@fluentui/react-components';

const Dashboard = () => {
  const { user, logout } = useContext(AuthContext);

  if (!user) {
    return <Text>No Role Found</Text>;
  }

  const displayName = user.profile?.firstName ? `${user.profile.firstName} ${user.profile.lastName}` : user.userEmail;

  return (
    <>
      {console.debug('Rendering Dashboard component in ProtectedRoute')}
      <Card>
        <CardHeader
          header={<Display>Welcome</Display>}
          description={`${displayName}, you are logged in as ${user.userEmail} and can access the following roles: ${user.roles.join(', ')}`}
          />
        <iframe
        src="https://logansrun-staging.rhemlabs.com.au/"
        style={{ width: '100%', height: '75vh', border: 'none' }}
        title="Logan"
      />
      </Card>
      
    </>
  );
};

export default Dashboard;