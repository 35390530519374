// src/components/shared/footer/FooterBase.js
import React from 'react';
import { useFooterStyles } from '@rhemlabs/rl-theme';
import { Text } from '@fluentui/react-components';

const FooterBase = ({theme}) => {
  const classes = useFooterStyles();

  return (
    <div className={classes.footerBase}>
      
      <Text size={100}> &copy; 2024 RHEM Labs Pty Ltd ACN: 672 769 354</Text>

    </div>
  );
};

export default FooterBase;