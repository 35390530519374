// src/components/shared/footer/FooterTop.js
import React from 'react';
import { useFooterStyles } from '@rhemlabs/rl-theme';
import { Text, Link } from '@fluentui/react-components';

const FooterTop = ({theme}) => {
  const classes = useFooterStyles();

  return (
    <div className={classes.footerTop}>
      <Text align="center">Follow Us On:</Text>
      <div className={classes.socialLinks}>
        <Link href="https://facebook.com/RHEMLabsPtyLtd" target="_blank">Facebook</Link>
        <Link href="https://x.com/RHEMLabsPtyLtd" target="_blank">X</Link>
        <Link href="https://au.linkedin.com/company/rhemlabs" target="_blank">LinkedIn</Link>
      </div>
    </div>
  );
};

export default FooterTop;