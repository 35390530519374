// src/components/shared/DigiCertSeal.js
import React, { useEffect, useState } from 'react';

/**
 * DigiCertSeal Component
 * This component integrates the DigiCert seal into the React application.
 * It dynamically inserts the DigiCert script into the DOM unless the app is running locally.
 * 
 * @component
 * @example
 * return (
 *   <DigiCertSeal />
 * )
 */
const DigiCertSeal = () => {
  const [isLocal, setIsLocal] = useState(false);

  useEffect(() => {
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      setIsLocal(true);
    } else {
      // Ensure __dcid is defined globally
      window.__dcid = window.__dcid || [];
      window.__dcid.push({ "cid": "DigiCertClickID_velZ_Rht", "tag": "velZ_Rht" });

      (function() {
        var cid = document.createElement("script");
        cid.async = true;
        cid.src = "//seal.digicert.com/seals/cascade/seal.min.js";
        cid.onload = () => console.log("DigiCert script loaded successfully.");
        cid.onerror = () => console.error("Failed to load DigiCert script.");
        console.log("Appending DigiCert script:", cid.src);
        // Inject the script into the head
        document.head.appendChild(cid);
      }());
    }
  }, []);

  return (
    <div id="DigiCertClickID_velZ_Rht">
      SSL SEAL
      {isLocal && (
        <div style={{ width: '150px', height: '50px', backgroundColor: '#f0f0f0', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ccc' }}>
          DigiCert Seal Placeholder
        </div>
      )}
    </div>
  );
};

export default DigiCertSeal;