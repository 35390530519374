// components/blog/CategoryPage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, CardFooter, CardHeader, Button, LargeTitle, Title3, CardPreview } from '@fluentui/react-components';
import ShareButton from './ShareButton'; 
import { useCardStyles, useLayoutStyles } from '@rhemlabs/rl-theme';

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

function CategoryPage() {
    const cardStyles = useCardStyles();
    const layoutStyles = useLayoutStyles();
    const { categoryName } = useParams();
    const [posts, setPosts] = useState([]);
    const [category, setCategory] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 9;

    useEffect(() => {
        // Fetch category details by category name
        fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/categories?slug=${categoryName}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    setCategory(data[0]);
                }
            });
    }, [categoryName]);

    useEffect(() => {
        if (category) {
            // Fetch posts from the category
            fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/posts?categories=${category.id}`)
                .then(response => response.json())
                .then(data => {
                    const fetchPostsWithMedia = data.map(post => {
                        if (post.featured_media) {
                            return fetch(`https://rl.azurewebsites.net/wp-json/wp/v2/media/${post.featured_media}`)
                                .then(response => response.json())
                                .then(mediaData => {
                                    // Check if source_url is available
                                    if (mediaData.source_url) {
                                        const baseURL = 'https://rl.azurewebsites.net';
                                        return { ...post, featured_media_url: baseURL + mediaData.source_url };
                                    } else {
                                        console.error('No source_url available for post', post.id);
                                        return post;
                                    }
                                })
                                .catch(error => {
                                    console.error('Error fetching media for post', post.id, ':', error);
                                    return post;
                                });
                        }
                        return Promise.resolve(post);
                    });
                    Promise.all(fetchPostsWithMedia).then(setPosts);
                })
                .catch(error => console.error('Error fetching posts:', error));
        }
    }, [category]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    if (!category) {
        return <div>Loading...</div>;
    }

    return (
        <div className="category-page">
          <LargeTitle>{category.name}</LargeTitle>
          <div className={layoutStyles.cardGrid}>
                {currentPosts.map(post => (
                    <Card key={post.id} className={cardStyles.card}>
                        <CardPreview>
                        <img 
                            src={post.featured_media_url} 
                            alt={"Feature, Decorative Only."}
                            style={{ width: "100%", height: "200px", objectFit: "cover", objectPosition: "50% 21%" }} 
                        /> 
                        </CardPreview>
                        <CardHeader
                            header={<Title3> {toTitleCase(post.title.rendered)}</Title3>}
                            description={<></>}
                        />
                            <div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />

                        <CardFooter>
                                <Link to={`/category/${categoryName}/post/${post.slug}`}>
                                    <Button appearance="primary">Read More</Button>
                                </Link>
                                <ShareButton postSlug={post.slug} categoryName={categoryName} postTitle={post.title.rendered} />
                        </CardFooter>
                    </Card>

                ))}
            </div>
            <div className="pagination" style={{ textAlign: 'center' }}>
                {Array.from({ length: Math.ceil(posts.length / postsPerPage) }, (_, i) => (
                    <button key={i + 1} onClick={() => paginate(i + 1)}>
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default CategoryPage;