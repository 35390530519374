import React from 'react';
import { useTheme } from '../../context/ThemeContext';

export default function LoganAIPricing() {
    const { theme } = useTheme();

    if (!theme ) {
        return <div>Loading theme...</div>;
    }

    return (
        <div style={{
            backgroundColor: theme.colorBrandBackground,
            color: theme.colorBrandForeground1,
            borderColor: theme.colorBrandStroke1,
            borderRadius: theme.radiusMedium,
            padding: theme.spacingLarge,
            margin: theme.spacingLarge,
            boxShadow: theme.shadow,

        }}>
            <stripe-pricing-table
            
            pricing-table-id="prctbl_1PwxT9Ck0kBAffOrlPkfrRBX"
            publishable-key="pk_live_51OINpOCk0kBAffOrqLqMDyKRatU6hIBfgMx2yXYbaLqC8TZXbXLI0IvspBWKRCy3rqvXi7Ije0SL4B3vEvraOk6k00ZyPABdgj"
                
            >
            </stripe-pricing-table>
        </div>
    );
};