// src/services/userService.js  
  
const API_URL = process.env.REACT_APP_API_BASE_URL;  
  
const userService = {  
  getUsers: async () => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/users`, {  
      credentials: 'include',  
    });  
    if (!response.ok) throw new Error('Failed to fetch users');  
    const data = await response.json();  
    return data.data;  
  },  
  
  addUser: async (userData) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/users`, {  
      method: 'POST',  
      headers: { 'Content-Type': 'application/json' },  
      credentials: 'include',  
      body: JSON.stringify(userData),  
    });  
    if (!response.ok) throw new Error('Failed to add user');  
    const data = await response.json();  
    return data.data;  
  },  
  
  updateUser: async (userId, userData) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/users/${userId}`, {  
      method: 'PUT',  
      headers: { 'Content-Type': 'application/json' },  
      credentials: 'include',  
      body: JSON.stringify(userData),  
    });  
    if (!response.ok) throw new Error('Failed to update user');  
    const data = await response.json();  
    return data.data;  
  },  
  
  deleteUser: async (userId) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/users/${userId}`, {  
      method: 'DELETE',  
      credentials: 'include',  
    });  
    if (!response.ok) throw new Error('Failed to delete user');  
    return true;  
  },  
  
  initiatePasswordReset: async (email) => {  
    // Implement the function to initiate password reset  
    const response = await fetch(`${API_URL}/rl-auth/v1/initiate-password-reset`, {  
      method: 'POST',  
      headers: { 'Content-Type': 'application/json' },  
      credentials: 'include',  
      body: JSON.stringify({ email }),  
    });  
    if (!response.ok) throw new Error('Failed to initiate password reset');  
    const data = await response.json();  
    return data;  
  },  
};  
  
export default userService;  
