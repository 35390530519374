import React, {useEffect} from 'react';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell } from '@fluentui/react-components';
import { useTheme } from '../../context/ThemeContext';

const featureData = [
  { feature: "Language Models", 
    loganAI: "GPT-3.5\nGPT-4\nGPT-4o\nGemini 1.5 Pro\nGemini 1.5 Flash", 
    chatGPT: "GPT only", 
    claude: "Claude only", 
    gemini: "Gemini only" },
  { feature: "Memory & Context", 
    loganAI: "Up to 1 million tokens (when using Gemini)", 
    chatGPT: "Up to 128K tokens", 
    claude: "Up to 200,000 tokens", 
    gemini: "Up to 1 million tokens" },
  { feature: "Australian Made", 
    loganAI: "Yes", 
    chatGPT: "No", 
    claude: "No", 
    gemini: "No" },
  { feature: "Prompt Customisation", 
    loganAI: "Extensive customisation backed by Azure Content moderation and jailbreak detection", 
    chatGPT: "Limited, through creative prompting", 
    claude: "Ethically constrained customisation", 
    gemini: "Less documented, potentially manual" },
  { feature: "Real-Time Information", 
    loganAI: "Coming Soon", 
    chatGPT: "Limited Browsing", 
    claude: "No", 
    gemini: "Limited, through Google services updates" },
  { feature: "Image Generation", 
    loganAI: "DALL-E 3,\nImagen (Coming Soon)", 
    chatGPT: "DALL-E", 
    claude: "Built in", 
    gemini: "Imagen" },
  { feature: "Code Generation", 
    loganAI: "Yes, Excels in prompt to app using Elementools™", 
    chatGPT: "Yes", 
    claude: "Yes", 
    gemini: "Yes" },
  { feature: "Multilingual Support", 
    loganAI: "Yes more than 50+ languages", 
    chatGPT: "Yes", 
    claude: "Yes", 
    gemini: "Yes" },
  { feature: "Creativity", 
    loganAI: "Customisable", 
    chatGPT: "Fixed", 
    claude: "Fixed", 
    gemini: "Fixed" },
  { feature: "Customisable User Interface", 
    loganAI: "Fully Customisable User Settings", 
    chatGPT: "No", 
    claude: "No", 
    gemini: "No" },
  { feature: "Integrations", 
    loganAI: "Build your own with Elementools™", 
    chatGPT: "Limited Tools", 
    claude: "APIs available", 
    gemini: "Integrated with Google’s ecosystem" },
  { feature: "Pricing (excluding tax)", 
        loganAI: "$37/month (AUD)", 
            chatGPT: "$45.55/month (AUD)\n($30/month USD)", 
            claude: "$37.96/month (AUD)\n($25/month USD)", 
            gemini: "$32.99/month (AUD)" }
];

const formatText = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const FeatureComparison = () => {
  const { theme } = useTheme();

  useEffect(() => {
    console.log('Current theme:', theme);
  }, [theme]);

  if (!theme ) {
    return <div>Loading theme...</div>;
  }


  return (
    <div style={{ backgroundColor: theme.colorNeutralBackground1}}>
      <Table>
        <TableHeader>
          <TableRow style={{ color: theme.colorNeutralBackground2, backgroundColor: theme.colorNeutralForeground2, fontWeight: 'bold' }}>
            <TableHeaderCell>Feature</TableHeaderCell>
            <TableHeaderCell style={{ backgroundColor: theme.colorBrandBackground, fontWeight: 'bold' }}>LoganAI</TableHeaderCell>
            <TableHeaderCell>ChatGPT</TableHeaderCell>
            <TableHeaderCell>Claude</TableHeaderCell>
            <TableHeaderCell>Gemini</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {featureData.map((item, index) => (
            <TableRow key={index}>
              <TableCell style={{ padding: '8px', borderBottom: `1px solid ${theme.colorNeutralStroke1}` }}>{item.feature}</TableCell>
              <TableCell style={{ padding: '8px', borderBottom: `1px solid ${theme.colorNeutralStroke1}`, backgroundColor: theme.colorBrandBackground2, fontWeight: 'bold' }}>{formatText(item.loganAI)}</TableCell>
              <TableCell style={{ padding: '8px', borderBottom: `1px solid ${theme.colorNeutralStroke1}` }}>{item.chatGPT}</TableCell>
              <TableCell style={{ padding: '8px', borderBottom: `1px solid ${theme.colorNeutralStroke1}` }}>{item.claude}</TableCell>
              <TableCell style={{ padding: '8px', borderBottom: `1px solid ${theme.colorNeutralStroke1}` }}>{item.gemini}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default FeatureComparison;