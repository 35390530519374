// src/pages/ai-for-law/WaitList.js
// pages/ai-for-law/WaitList.js

import React, { useState } from 'react';
import { Button, Input, Field, Display, Subtitle1, Text, MessageBar, MessageBarBody, MessageBarTitle, Spinner } from '@fluentui/react-components';
import { useMiscStyles } from '@rhemlabs/rl-theme';
import { EmailClient } from '@azure/communication-email';

const WaitList = () => {
  const styles = useMiscStyles();
  const [formData, setFormData] = useState({ email: '' });
  const [errors, setErrors] = useState({ email: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null); // null, 'success', 'error'

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    // Basic validation
    if (!formData.email || !emailRegex.test(formData.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
      hasErrors = true;
    }

    if (!hasErrors) {
      setIsSubmitting(true);
      setSubmitSuccess(null);

      // Sending email using Azure Communication Services
      const connectionString = process.env.REACT_APP_EMAIL_CONNECTION_STRING;
      const senderAddress = process.env.REACT_APP_EMAIL_SENDER_ADDRESS;

      // Ensure these values are defined
      if (!connectionString || !senderAddress) {
        console.error('Environment variables are not set correctly.');
        setIsSubmitting(false);
        setSubmitSuccess('error');
        return;
      }

      const client = new EmailClient(connectionString);

      const emailMessage = {
        senderAddress,
        content: {
          subject: 'AI For Law Waitlist Form Submission',
          plainText: `Email: ${formData.email}`,
        },
        recipients: {
          to: [{ address: process.env.REACT_APP_EMAIL_DEFAULT_RECIPIENT }],
        },
      };

      try {
        const poller = await client.beginSend(emailMessage);
        const response = await poller.pollUntilDone();

        if (response.status === 'Succeeded') {
          setSubmitSuccess('success');
        } else {
          console.error('Error sending email:', response);
          setSubmitSuccess('error');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitSuccess('error');
      } finally {
        setIsSubmitting(false);
        setFormData({ email: '' });
      }
    }
  };

  return (
    <div className={styles.WaitListContainer}>
      <Display>Join the Wait List<br/></Display>
      <Subtitle1>Sign up to be among the first to access LoganAI for Australian Lawyers, Police, and Expert Witnesses.</Subtitle1>
      {submitSuccess === 'success' && (
        <MessageBar intent="success">
          <MessageBarBody>
            <MessageBarTitle>Success</MessageBarTitle>
            You've successfully joined the wait list!
          </MessageBarBody>
        </MessageBar>
      )}
      {submitSuccess === 'error' && (
        <MessageBar intent="error">
          <MessageBarBody>
            <MessageBarTitle>Error</MessageBarTitle>
            There was an error submitting your form. Please try again.
          </MessageBarBody>
        </MessageBar>
      )}
      {isSubmitting ? (
        <Spinner label="Submitting..." />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className={styles.FormGroup}>
            <Field
              label="Email"
              required
              placeholder="Your email"
              validationState={errors.email ? 'invalid' : 'none'}
              validationMessage={errors.email}
            >
              <Input size="large" name="email" onChange={handleChange} value={formData.email} />
            </Field>
          </div>
          <Button type="submit" appearance="primary">Submit</Button>
          <Text size={100}><p/>By clicking this button you agree to receive marketing emails from RHEM Labs in accordance with our <a href="/privacy-policy">Privacy Policy</a>.</Text>
        </form>
      )}
    </div>
  );
};

export default WaitList;