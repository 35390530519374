import React from 'react';
import {
  Text,
  Display,
  Title1,

} from "@fluentui/react-components";



export default function About() {

  return (
  <>
    <Display>Who We Are</Display>
    <Title1><p>Engineering a More Just Future</p></Title1>
    

    <Text>
      RHEM Labs emerged from a deeply personal mission: to transform adversity into opportunity and build a more just and accessible world. Dr. Richard Matthews, a military veteran and technology expert with almost 20 years engineering, research and design exxerience, experienced firsthand the impact of life-altering events decided to focus efforts on solving the worlds grand challenges. Inspired to make a tangible difference, he channeled his expertise into creating solutions that empower others.
      Today, RHEM Labs stands at the forefront of forensic technology and generative AI, driven by a commitment to three core areas that are reshaping investigations and legal practices.
    </Text>



  </>
  );
}