// RHEMLabs.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, Text } from '@fluentui/react-components';
import BadgeDark from '../../assets/logo.jpeg';
import BadgeLight from '../../assets/logo.jpeg';
import MonoBlack from '../../assets/logo.jpeg';
import MonoWhite from '../../assets/logo.jpeg';
import { RHEMDark, useHeaderStyles } from '@rhemlabs/rl-theme';

const RHEMLabs = ({ theme, logoType }) => {
  const [logoSrc, setLogoSrc] = useState('');
  const classes = useHeaderStyles();

  useEffect(() => {
    const isDarkTheme = theme === RHEMDark;
    switch (logoType) {
      case 'badge':
        setLogoSrc(isDarkTheme ?  BadgeDark : BadgeLight);
        break;
      case 'mono':
        setLogoSrc(isDarkTheme ? MonoWhite : MonoBlack);
        break;
      default:
        setLogoSrc(isDarkTheme ? BadgeDark : BadgeLight); // Fallback logic
    }
  }, [theme, logoType]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <Image 
            src={logoSrc} 
            alt="RHEM Labs Logo" 
            shape="rounded"
            style={{ 
              margin: '10px', 
              height: '100px', 
              width: '100px',
              '@media (maxWidth: 768px)': {
                height: '50px',
                width: '50px',
              }
            }}
        />
        <Link to="/" className={classes.headerButton}> 
            <Text 
                style={{ 
                  fontSize: '36px', 
                  fontWeight: 'bold', 
                  '@media (maxWidth: 768px)': {
                    fontSize: '18px',
                  }
                }}>
                RHEM Labs
            </Text>
        </Link>
    </div>
  );
};

export default RHEMLabs;