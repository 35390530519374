// fluent_ui/src/services/productService.js  
// src/services/productService.js  
  
const API_URL = process.env.REACT_APP_API_BASE_URL;  
  
const productService = {  
  getProducts: async () => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/products`, {  
      credentials: 'include',  
    });  
    if (!response.ok) throw new Error('Failed to fetch products');  
    const data = await response.json();  
    return data.data;  
  },  
  
  addProduct: async (productData) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/products`, {  
      method: 'POST',  
      headers: { 'Content-Type': 'application/json' },  
      credentials: 'include',  
      body: JSON.stringify(productData),  
    });  
    if (!response.ok) throw new Error('Failed to add product');  
    const data = await response.json();  
    return data.data;  
  },  
  
  updateProduct: async (productId, productData) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/products/${productId}`, {  
      method: 'PUT',  
      headers: { 'Content-Type': 'application/json' },  
      credentials: 'include',  
      body: JSON.stringify(productData),  
    });  
    if (!response.ok) throw new Error('Failed to update product');  
    const data = await response.json();  
    return data.data;  
  },  
  
  deactivateProduct: async (productId) => {  
    const response = await fetch(`${API_URL}/rl-auth/v1/products/${productId}`, {  
      method: 'DELETE',  
      credentials: 'include',  
    });  
    if (!response.ok) throw new Error('Failed to deactivate product');  
    return true;  
  },  
};  
  
export default productService;  
