// fluent_ui/src/components/protected/ProtectedRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../auth/context/AuthContext';

const ProtectedRoute = ({ roles, children }) => {
  const { user, loading } = useContext(AuthContext);

  console.debug('ProtectedRoute - user:', user);
  console.debug('ProtectedRoute - required roles:', roles);
  console.debug('ProtectedRoute - loading state:', loading);

  if (loading) {
    console.debug('ProtectedRoute - Still loading user data, rendering loading indicator...');
    return <div>Loading...</div>;
  }

  if (!user || !roles.some(role => user.roles.includes(role))) {
    console.debug('ProtectedRoute - No user or required role not found, redirecting to login.');
    return <Navigate to="/app/loganAI-login" />;
  }

  console.debug('ProtectedRoute - User authorized, rendering component.');
  return children;
};

export default ProtectedRoute;