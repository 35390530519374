// src/pages/admin/AdminLayout.js
/**
 * File: src/pages/admin/AdminLayout.js
 * Description: The layout component for the admin section, including a sidebar and main content area.
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import { useLayoutStyles } from '@rhemlabs/rl-theme';

const AdminLayout = () => {
  const styles = useLayoutStyles();

  return (
    <div className={styles.adminLayout}>
      {console.debug('Rendering AdminLayout component in ProtectedRoute')}
      <AdminSidebar />
      <div className={styles.adminContent}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;