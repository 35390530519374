
import * as React from 'react';


export default function LoganAIDemo() {


    return (
    <div>
      {/* LoganAi Demo */}
    </div>
    );
};

