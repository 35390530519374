// src/components/shared/header/header_main.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Button } from '@fluentui/react-components';
import RHEMLogo from '../../../assets/logo.jpeg';
import { useHeaderStyles } from '@rhemlabs/rl-theme';

function HeaderMain() {
    const classes = useHeaderStyles();
    return (
        <div className={classes.headerMain}>
            <div className={classes.headerColumnLeft}>
                <Image
                    src={RHEMLogo}
                    alt="RHEM Labs Logo"
                    height={48}
                    width={48}
                />
                <Link to="/" className={classes.headerButton}> 
                    <span className={classes.headerText} style={{marginLeft:"5px"}}>RHEM Labs</span>
                </Link>
            </div>
            <div className={classes.headerColumnRight}>
                <Link to="/app/loganAI-login">
                    <Button appearance="primary" as="span" className={classes.button}>
                        Client Login
                    </Button>
                </Link>
                <Link to="/products/logan-ai">
                    <Button appearance="outline" as="span" className={classes.button}>
                        Try LoganAI
                    </Button>
                </Link>
                
            </div>
        </div>
    );
}

export default HeaderMain;