// forensic-services/Bookings.js
import * as React from 'react';
import { Card, CardHeader, CardPreview, Display, Subtitle1 } from '@fluentui/react-components';
import { useCardStyles } from '@rhemlabs/rl-theme';

export default function BookingPage() {
    const classes = useCardStyles();

    return (
        <Card className={classes.SectionCard}>
            <CardPreview>
            </CardPreview>
            <CardHeader className={classes.SectionHeader}
                header={<Display>Bookings</Display>}
                description={<Subtitle1 style={{height: `${1.5 * 3}em`}}> Book a Time with Our Experts</Subtitle1>}
            />
            <div style={{ position: 'relative', height: '80vh', width: '100%' }}>
            <iframe
    src="https://outlook.office365.com/owa/calendar/RHEMLabs1@rhemlabs.com.au/bookings/"
    style={{
            position: 'absolute',
            top: 0,  // Adjust this value to hide the top 200px of the iframe content
            left: 0,
            width: '100%',
            height: '80vh',  // Adjusting height to compensate for the hidden part
            border: 0,
            overflow: 'auto',  // Use overflow property to control scrolling
            WebkitOverflowScrolling: 'touch'  // Enable smooth scrolling on iOS
    }}
    allowFullScreen
    title="Booking Page"
></iframe>
            </div>
        </Card>
    );
};