// components/shared/footer/Footer.js
import React, { useState, useEffect } from 'react';
import FooterTop from './FooterTop';
import FooterMain from './FooterMain';
import FooterBase from './FooterBase';

const Footer = ({ theme }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    <footer>
      {isMobile ? (
        <>

          <FooterMain theme={theme} isMobile={isMobile} />
          <FooterBase theme={theme} />
        </>
      ) : (
        <>

          <FooterMain theme={theme} />
          <FooterBase theme={theme} />
        </>
      )}
    </footer>
  );
};

export default Footer;