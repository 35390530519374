// Location: src/components/shared/Spacer.js
// Date: 20240930
// Author: R Matthews
// Purpose: Component to add white space to the page layout

import React from 'react';
import PropTypes from 'prop-types';

const Spacer = ({ height }) => {
  const spacerStyle = {
    width: '100%',
    height: height || '1rem', // Default to 1rem if height is not provided
    '@media (minWidth: 768px)': {
      height: height || '2rem', // Increase height on larger screens
    },
  };

  return <div style={spacerStyle}></div>;
};

Spacer.propTypes = {
  height: PropTypes.string,
};

export default Spacer;