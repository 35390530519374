// components/LoganAIPasswordReset.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mergeClasses, Card, Input, Field, Button, Link, Image, makeStyles, MessageBar, CardHeader, CardFooter } from '@fluentui/react-components';
import { rhemlabs } from '@rhemlabs/rl-theme';
import authService from '../services/authService';
import RHEMLogo from '../../../assets/logo.jpeg';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: `linear-gradient(135deg, ${rhemlabs[80]} 0%, ${rhemlabs[140]} 100%)`,
  },
  card: {
    width: '100%',
    maxWidth: '400px',
    padding: '2rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  input: {
    marginBottom: '1rem',
  },
  button: {
    alignSelf: 'center',
    padding: '0.5rem 2rem',
  },
  footer: {
    textAlign: 'center',
    paddingTop: '1rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  messageBar: {
    marginBottom: '1rem',
  },
  logo: {
    height: '48px',
    width: '48px',
    borderRadius: '8px',
  },
  pointer: {
    cursor: 'pointer',
  },
});

const LoganAIPasswordReset = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();
    setMessage('');
    setErrorMessage('');
  
    if (!email) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
  
    try {
      await authService.initiatePasswordReset(email);
      setMessage('Password reset link has been sent to your email.');
    } catch (error) {
      setErrorMessage('Failed to initiate password reset. Please try again.');
    }
  };

  const combinedClasses = mergeClasses(classes.logo, classes.pointer);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <a href="/">
          <Image src={RHEMLogo} alt="Home" className={combinedClasses} aria-label="Home" />
        </a>
        <CardHeader className={classes.header}
        header={<h4>Password Reset</h4>}>
        </CardHeader>
        {errorMessage && (
          <MessageBar className={classes.messageBar} intent="error">
            {errorMessage}
          </MessageBar>
        )}
        {message && (
          <MessageBar className={classes.messageBar} intent="success">
            {message}
          </MessageBar>
        )}
        <form className={classes.form} onSubmit={handlePasswordResetRequest}>
          <Field label="Email">
            <Input
              className={classes.input}
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Field>
          <Button className={classes.button} type="submit" appearance="primary">
            Submit
          </Button>
        </form>
        <CardFooter className={classes.footer}>
          <Link href="/app/loganAI-login">Back to Login</Link>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoganAIPasswordReset;