// OpenAI.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@fluentui/react-components';
import BadgeDark from '../../assets/partnerLogos/powered-by-openai-badge-filled-on-dark.svg';
import BadgeLight from '../../assets/partnerLogos/powered-by-openai-badge-filled-on-light.svg';
import MonoBlack from '../../assets/partnerLogos/openai-white-lockup.png';
import MonoWhite from '../../assets/partnerLogos/openai-lockup.png';
import { RHEMDark } from '@rhemlabs/rl-theme';

const PoweredByOpenAI = ({ theme, logoType }) => {
  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    const isDarkTheme = theme === RHEMDark;
    switch (logoType) {
      case 'badge':
        setLogoSrc(isDarkTheme ?  BadgeDark : BadgeLight);
        break;
      case 'mono':
        setLogoSrc(isDarkTheme ? MonoWhite : MonoBlack);
        break;
      default:
        setLogoSrc(isDarkTheme ? BadgeDark : BadgeLight); // Fallback logic
    }
  }, [theme, logoType]);

  return (
    <div style={{ height: 'auto', width: 180, padding: 8 }}>
        <Link to="/poweredby">
            <Image 
                src={logoSrc} 
                alt="Open AI Powered By Badge" 
                fit="contain"
            />
        </Link>
    </div>
);
};

export default PoweredByOpenAI;