// fluent_ui/src/components/auth/components/Register.js
import React, { useState } from 'react';
import { Card, CardHeader, CardFooter, Input, Button, MessageBar, makeStyles } from '@fluentui/react-components';
import { rhemlabs } from '@rhemlabs/rl-theme';
import authService from '../services/authService';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: `linear-gradient(135deg, ${rhemlabs[80]} 0%, ${rhemlabs[140]} 100%)`,
    },
    card: {
        width: '100%',
        maxWidth: '400px',
        padding: '2rem',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    input: {
        marginBottom: '1rem',
    },
    button: {
        alignSelf: 'center',
        padding: '0.5rem 2rem',
    },
    footer: {
        textAlign: 'center',
        paddingTop: '1rem',
    },
    header: {
        textAlign: 'center',
        marginBottom: '1rem',
    },
    messageBar: {
        marginBottom: '1rem',
    }
});

const Register = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setMessage('');

        if (!email) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            await authService.initiateRegistration(email);
            setMessage('Please check your email to complete the registration process.');
        } catch (error) {
            setErrorMessage('Failed to initiate registration. Please try again.');
        }
    };

    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardHeader className={classes.header}>
                    <h2>Register</h2>
                </CardHeader>
                {errorMessage && (
                    <MessageBar className={classes.messageBar} intent="error">
                        {errorMessage}
                    </MessageBar>
                )}
                {message && (
                    <MessageBar className={classes.messageBar} intent="success">
                        {message}
                    </MessageBar>
                )}
                <form className={classes.form} onSubmit={handleRegister}>
                    <Input
                        className={classes.input}
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button className={classes.button} type="submit" appearance="primary">
                        Register
                    </Button>
                </form>
                <CardFooter className={classes.footer}>
                    <p>
                        Already have an account? <a href="/app/loganAI-login">Login here</a>
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
};

export default Register;