// header/Header.js
import React, { useState, useEffect } from 'react';
import HeaderTop from './header_top';
import HeaderMain from './header_main';
import HeaderBottom from './header_bottom';
import HeaderMobile from './HeaderMobile';
import { Divider, makeStyles, shorthands, tokens } from '@fluentui/react-components';

const useDividerStyles = makeStyles ({
    dividerTesting: {
   "::before": {
     ...shorthands.borderColor(tokens.colorStrokeFocus2),
   },
   "::after": {
     ...shorthands.borderColor(tokens.colorStrokeFocus2),
   },
 }
 
 })

function Header(props) {
    const dividerStyles = useDividerStyles();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkIsMobile();
        window.addEventListener('resize', checkIsMobile);

        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);

    return (
        <header>
            {isMobile ? (
                <HeaderMobile toggleTheme={props.toggleTheme}/>
            ) : (
                <>
                    <HeaderTop toggleTheme={props.toggleTheme} />
                    <HeaderMain />
                    <HeaderBottom />
                </>
            )}
                  <Divider className={dividerStyles.dividerTesting}></Divider>
                  <Divider className={dividerStyles.dividerTesting} vertical></Divider>
        </header>
    );
}

export default Header;